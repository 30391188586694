import React, { useState } from 'react';
import { useHistory} from "react-router-dom";
import "../screens/ErrorScreen.css";
import logo from "../assets/Logo-demoqrata-white.svg";
import Copyright from '../components/Footer/Copyright';

function ErrorPage({ error }) {

  const history = useHistory();

  const handleBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleHome = (e) => {
      e.preventDefault();
      history.push("/")
  };

  return (
    <div>
      <div className="errorScreen">
        <header className="errorScreen__banner"
        style={{
            backgroundSize: "cover",
            backgroundImage: `linear-gradient(to top, #111, transparent), 
                  url(
                "https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fdemoqrata_cover_error.webp?alt=media&token=1cb0542c-0189-4f5d-8b2d-01d6dc88c387"
            )`, 
            backgroundPosition: "center center",
            height:"100%",
            width:"auto",
            objectFit:"contain",
        }}>

            
        <div className="errorScreen__content">

          <div className="errorScreen__msg">
              <h2 className="errorScreen__msg__t1">¡Oops!</h2>

              <p className="errorScreen__msg__t2">Algo salió mal.</p>

              <p className="errorScreen__msg__t3">{error?.toString() || "404"}</p>

              <button onClick={handleBack}>Volver atrás</button>
              <button onClick={handleHome}>Ir al inicio</button>

              <div className='errorScreen__logo'>
                <img
                    src={logo}
                    alt="demoqrata" title="Sólo la verdad."
                />
              </div>

          </div>
          
        </div>
        </header>
        </div>
        {/* <SocialLinks/> */}
        <Copyright/>
    </div>
  );
}

export function useErrorBoundary() {
  const [error, setError] = useState(null);

  const ErrorBoundary = ({ children }) => {
    if (error) {
      return <ErrorPage error={error} />;
    }
    return children;
  };

  const handleError = (error) => {
    setError(error);
  };

  const resetError = () => {
    setError(null);
  };

  return { ErrorBoundary, handleError, resetError, ErrorPage };
}