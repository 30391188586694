import React/* , { useState } */ from 'react';

import "./Headline.css";
import SignalWifi2BarSharpIcon from '@material-ui/icons/SignalWifi2BarSharp';
/* import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp'; */
import dot from "../assets/dot.svg";

function Headline() {
    
    function generateRandomIntegerInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    let usersconnected = generateRandomIntegerInRange(7500, 12000)
    
    let usersconnectedcomma = usersconnected.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <div className="headline">
            <div className="headline__contents" >
                <div className="headline__contents__signal">
                <SignalWifi2BarSharpIcon style={{
                fontSize: 16,
                justifyItems: "middle",
                margin: "0 5px"
                }} />
                <p style={{ marginRight:"20px" }} >Estamos en señal de prueba. Disfruta GRATIS del mejor contenido de demoqrata hasta el 31 de marzo.</p>
                </div>
                
                <div className="headline__contents__usersconnected">
                <img className="dot_svg" src={dot} />
                <p>conectados ahora: {usersconnectedcomma} demoqratas</p>
                </div>
                
            </div>
        </div>
    )
}

export default Headline;