import React from 'react';

import FacebookIcon from './FacebookIcon';
import InstagramIcon from './InstagramIcon';
import TwitterIcon from './TwitterIcon';
import YoutubeIcon from './YoutubeIcon';

import './SocialLinks.css';

const SocialLinks = () => {
    return (
        <div className="box">
            <a href="https://www.facebook.com/demoqrata" target="_blank" rel="noreferrer" className="iconLink">
                <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/demoqrata/" target="_blank" rel="noreferrer" className="iconLink">
                <InstagramIcon />
            </a>
            <a href="https://twitter.com/demoqrata" target="_blank" rel="noreferrer" className="iconLink">
                <TwitterIcon />
            </a>
            <a href="https://www.youtube.com/channel/UCUcenIWc-YsLKNGxb0ZVSrQ" target="_blank" rel="noreferrer" className="iconLink">
                <YoutubeIcon />
            </a>
        </div>
    );
};

export default SocialLinks;