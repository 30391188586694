const requests = {
    fetchLoader: `/loader`,
    fetchFamilyLV: `/familylv`,
    fetchFamilySD: `/familysd`,
    fetchNews: `/news`,
    fetchCartoon: `/cartoon`,
    fetchPeliculas: `/peliculas`,
    fetchComedia: `/comedia`,
    fetchTodo: `/todo`,
    fetchNoticiastodo: `/noticiastodo`,
    fetchDocumtodo: `/documtodo`,
    fetchCocinatodo:  `/cocinatodo`,
    fetchComediatodo: `/comediatodo`,
    fetchHumornegro: `/humornegro`,
    fetchLrc: `/lrc`,
    fetchCartoontodo: `/cartoontodo`,
    fetchNovela1todo: `/novela1todo`,
    fetchNovela2todo: `/novela2todo`,
    fetchNovela3todo: `/novela3todo`,
    fetchTecnologiatodo: `/tecnologiatodo`,
    fetchViajestodo: `/viajestodo`,
    fetchSerie1todo: `/serie1todo`,
    fetchPeliculastodo: `/peliculastodo`,
    fetchMascotastodo: `/mascotastodo`,
}

export default requests;