import React, { useEffect, useState, useContext } from 'react';
import { KeywordsContext } from '../context/KeywordsContext';
import "./KeywordsVideos.css"
import VideoCard from './VideoCard';
import FlipMove from 'react-flip-move';
import Skeleton from "./skeleton/Skeleton";
import { useDispatch } from 'react-redux';
/* import { getVideosBySearch } from '../redux/actions/videos.action'; */
import { creator } from '../assets/creator';

function KeywordsVideos() {

    const [words, setWords] = useContext(KeywordsContext);
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    //let palabraclave = words[2]?.keyword;
    /* console.log("words:", words);
    console.log("words en 0:", words[2]?.keyword);
    console.log(typeof palabraclave);
    console.log(palabraclave); */

    /* useEffect(() => {
        dispatch(getVideosBySearch(palabraclave))
     }, [palabraclave, dispatch]) */
    
    useEffect(() => {
        async function fetchData() {
            setVideos(
                (creator.sort(function(x, y){
                    return Date.parse(x.published) - Date.parse(y.published);
                }).slice(creator.length-50)).reverse()
           );
           return creator;
        };
        setVideos({});
        fetchData();
    }, []);

    const aaa = /* JSON.stringify( */words.map(getKeywords)/* ) */;
    function getKeywords(item) {
    return item.keyword;
    }
    /* console.log(typeof aaa);
    console.log(aaa.length);
    console.log(aaa);
    console.log(aaa[0]); */

    return (
        <div className="keywordsVideos">
            <div className="keywordsVideos__msg">
                <h2>Tu contenido personalizado</h2>
            </div>
            <div className="keywordsVideos__videocard">
            <FlipMove>
            {isLoading ? (
            <Skeleton type="feed" />
            ) : (
            videos.filter((video, key) => 

                {if (aaa.length === 1) {
                    return (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[0].toLowerCase()) 
                } else if (aaa.length === 2) {
                    return (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[0].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[1].toLowerCase())
                } else if (aaa.length === 3) {
                    return (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[0].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[1].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[2].toLowerCase())
                } else if (aaa.length === 4) {
                    return (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[0].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[1].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[2].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[3].toLowerCase())
                } else if (aaa.length === 5) {
                    return (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[0].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[1].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[2].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[3].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[4].toLowerCase())
                } else if (aaa.length === 6) {
                    return (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[0].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[1].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[2].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[3].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[4].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[5].toLowerCase())
                } else if (aaa.length >= 7) {
                    return (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[0].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[1].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[2].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[3].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[4].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[5].toLowerCase()) ||
                    (video?.title.toLowerCase() && 
                    JSON.stringify(video?.['media:group']?.['media:description']).toLowerCase()).includes(aaa[6].toLowerCase())
                }  
            }
                
            ).map((video, key) => (
                <VideoCard 
                    key={video.id} 
                    video={video} 
                />
            ))
            )}  
            </FlipMove>
            </div>
        </div>
    )
}

export default KeywordsVideos;