import React, { useState } from 'react';
import './CreadoresUpdate.css';
import creationJson from "../creatorList.json";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function Creadores() {
    const [selectCreador, setSelectCreador] = useState("");
    const { creatorId } = useParams();
    
    return (
    <div className="creadores__update">
        <div className="creadores__container">
        <h2 className="creadores__title">Otros creadores que te pueden interesar</h2>
        <p className="creadores__explora">Explora presionando SHIFT + rueda del mouse</p>
        </div>
        <div className="creadores__posters" title="">
        
        {creationJson
        .filter((poster) => 
        !poster.canalId.includes(creatorId)
        ).map((poster, key) => (
            <div className = {`creadores__poster__props`} key={poster.id}>
                <Link to={`/creators/${poster.canalId}`}>
                <img 
                    key={poster.id}
                    id={poster.canalId}
                    /* onClick={() => submitCreador()} */
                    value={selectCreador}
                    className= {`creadores__update__poster`}
                    src={poster.canalPosterUrl} 
                    title={poster.canalCategoria}
                    alt={poster.nombreCanal}
                />
                </Link>
            </div>
            ))}
    </div>
    </div>
    );
}

export default Creadores;