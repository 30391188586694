export const dmqintro = [
{
	"id": 1,
	"introId": "kCiOQwOE8os"
},
{
	"id": 2,
	"introId": "oBiXJO-idhU"
},
{
	"id": 3,
	"introId": "_A-L8tpnxVc"
},
{
	"id": 4,
	"introId": "l3F7XrLtUas"
},
{
	"id": 5,
	"introId": "g8u2dhE7p8E"
},
{
	"id": 6,
	"introId": "dvIHfvwFDpk"
},
{
	"id": 7,
	"introId": "1ZIJG8sHlIE"
}
]