import React, { useEffect, useState, useContext } from 'react';
import { KeywordsContext } from '../context/KeywordsContext';
import "./PersonalizeScreen.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer/Footer";
import Copyright from "../components/Footer/Copyright";
/* import Headline from "../components/Headline"; */
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';
import KeywordsVideos from '../components/KeywordsVideos';
import requests from '../requests';
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';
import { serverTimestamp } from "firebase/firestore";
import KeywordListItem from "../components/Keywords";

function PersonalizeScreen() {
    
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const db = firebaseApp.firestore();
        
    const [words, setWords] = useContext(KeywordsContext);
    const [wordInput, setWordInput] = useState("");

    useEffect(() => {
        getKeywords();
      }, []);

    function getKeywords() {
    try {
        db.collection('keywords')
        .where("user_id", "==", user.uid)
        .onSnapshot(function (querySnapshot) {
            setWords(
            querySnapshot.docs.map((doc) => ({
                id: doc.id,
                keyword: doc.data().keyword,
            }))
            );
        });    
    } catch (error) {
        console.log(error);
    }
    }

    async function addKeyword(e) {
        e.preventDefault();
        if (wordInput === "") {
            alert ("Ingrese una palabra")
        } else {
            try {
                await db.collection('keywords')
                    .add({
                        timestamp: serverTimestamp(),
                        keyword: wordInput,
                        user_id: user.uid,
                    });
                    setWordInput("");
            } catch (error) {
                console.log(error);
            }
        }
      }
      //console.log(words);

      const setSync = async (e) => {
        e.preventDefault();
        try {
            window.location.reload(false);
          } catch(err) {
            console.log(err);
          }
      }

    return (
        <div className="personalize">
            {/* <Headline /> */}
            <Nav />
            <div className="personalize__container">
                <div className="personalize__keywords">
                <p>Palabras claves</p>
                <div className="personalize__keywords__content">
                    <p>¿Qué palabras son importantes para ti?</p>
                    <p className="personalize__keywords__content__aux">agrega un máximo de 7 palabras. Una por una.</p>
                    <input value={wordInput} onChange={(e) => setWordInput(e.target.value)} id="yourwords" type="text" placeholder="libertad..."></input>
                    <button type="submit" onClick={addKeyword}>Agregar</button>
                </div>
                <div className="personalize__keywords__list" style={{ width: "50vw", maxWidth: "200px" }}>
                    {words.map((keyword) => (
                        <KeywordListItem 
                            keyword={keyword.keyword}
                            id={keyword.id}
                        />
                    ))
                    }
                <button id={ user.uid } onClick={ setSync } type="submit">Sincronizar el contenido</button>
                </div>
                
                </div>
                <div className="personalize__interestingvideos">
                    <KeywordsVideos style={{ paddingTop:"40px" }} />
                </div>
            </div>
            <Footer />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </div>
    )
}

export default PersonalizeScreen;