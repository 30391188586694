import React, { /* useState,  */useContext } from 'react';
import { SearchContext } from '../context/SearchContext';
import "./SearchScreen.css";
import Nav from "../components/Nav";
import RecommendedVideos from "../components/RecommendedVideos";
import GoToTop from '../GoToTop';


function SearchScreen() {
    //const [inputSearch, setInputSearch] = useState('');
    const [inputSearch, setInputSearch] = useContext(SearchContext);

    return (
        <div>
            <Nav />
            <div className="search__container">
                <RecommendedVideos  setInputSearch={setInputSearch} />
            </div>
            <GoToTop />
        </div>
    )
}

export default SearchScreen;
