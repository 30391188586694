import React from 'react';

const YoutubeIcon = () => (
    <svg width="37" height="26" viewBox="0 0 37 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M35.461 4.71284C35.2572 3.96805 34.8602 3.29375 34.3122 2.76163C33.7487 2.21315 33.058 1.82081 32.3055 1.62164C29.4888 0.861134 18.2043 0.861134 18.2043 0.861134C13.5 0.806305 8.79676 1.04746 4.12124 1.58323C3.36863 1.79712 2.67928 2.19822 2.11451 2.75088C1.55959 3.29783 1.15764 3.9723 0.947671 4.7113C0.443278 7.49482 0.19821 10.3211 0.215769 13.1522C0.197772 15.9806 0.442239 18.806 0.947671 21.593C1.15314 22.329 1.55359 23.0004 2.11001 23.5427C2.66644 24.085 3.35935 24.4768 4.12124 24.6842C6.97536 25.4432 18.2043 25.4432 18.2043 25.4432C22.9147 25.4981 27.6239 25.2569 32.3055 24.7211C33.058 24.5219 33.7487 24.1296 34.3122 23.5811C34.8671 23.0388 35.2616 22.3643 35.4595 21.6299C35.9771 18.8475 36.2287 16.02 36.2109 13.1875C36.2499 10.3431 35.9985 7.50223 35.461 4.7113V4.71284ZM14.6168 18.4127V7.89314L24.0056 13.1537L14.6168 18.4127Z"
            fill="#808080"
        />
    </svg>
);

export default YoutubeIcon;