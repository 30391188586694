import React from "react";

import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";

import "./NavBottom.css";

import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import CategorySharpIcon from "@material-ui/icons/CategorySharp";
import MonetizationOnSharpIcon from "@material-ui/icons/MonetizationOnSharp";
import { VerifiedUserSharp, FilterCenterFocusSharp, TvOffSharp } from "@material-ui/icons/";


function NavBottom() {
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
return (
    <div className="navbottom__icons">
        <Link to="/" style={{ textDecoration: 'none', color:"white" }}>
        <div className="navbottom__icon navbottom__icon--active">
            <div className="navbottom__icon-content">
                <HomeSharpIcon />
                <p>Inicio</p>
            </div>
        </div>
        </Link>

        <Link to="/categories" style={{ textDecoration: 'none', color:"white" }}>
        <div className="navbottom__icon">
            <div className="navbottom__icon-content">
                <CategorySharpIcon />
                <p>Categorías</p>
            </div>
        </div>
        </Link>

        <Link to="/profile" style={{ textDecoration: 'none', color:"white" }}>
        <div className="navbottom__icon">
            <div className="navbottom__icon-content">
                <VerifiedUserSharp />
                <p>Perfil</p>
            </div>
        </div>
        </Link>

        <Link to="/donation" style={{ textDecoration: 'none', color:"white" }}>
        <div className="navbottom__icon">
            <div className="navbottom__icon-content">
                <MonetizationOnSharpIcon />
                <p>Donación</p>
            </div>
        </div>
        </Link>

        <Link to="/personalize" style={{ textDecoration: 'none', color:"white" }}>
        <div className="navbottom__icon">
            <div className="navbottom__icon-content">
                <FilterCenterFocusSharp />
                <p>Personalizar</p>
            </div>
        </div>
        </Link>

        <div>
        <div className="navbottom__icon">
            <div className="navbottom__icon-content">
                <TvOffSharp onClick={() => {
                        auth.signOut();
                        window.location.reload();
                    }} />
                <p>Salir</p>
            </div>
        </div>
        </div>
        
    </div>
)
}

export default NavBottom;
