import React, { useState, useEffect } from 'react';
import "./VideoScreen.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer/Footer";
import Copyright from "../components/Footer/Copyright";
/* import Headline from "../components/Headline"; */
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';
import Skeleton from "../components/skeleton/Skeleton";
import { ThumbUpSharp } from "@material-ui/icons";
import { creator } from '../assets/creator';

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

function TvScreen() {
    const [creation,setCreation] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState("YmCOsDw85zk");


    useEffect(() => {
        async function fetchData() {
            setCreation(
                (creator.sort(function(x, y){
                    return Date.parse(x.published) - Date.parse(y.published);
                }).slice(creator.length-50)).reverse()
           );
           return creator;
        }
        fetchData();
    }, []);

    /* const _videoId = creation[0]?.videoId;
    console.log(typeof _videoId); */

    /* const handleClickTv = (e) => {
        setSelected(video.videoId);
        history.push(`/tv/${selected}`)
    } */
    /* const handleClickTv = () => {
    history.push(`/watch/${}`)
    } */

    return (
        <div>
            {/* <Headline /> */}
            <Nav />
            <div className="tv">
                <div className="tv__player">
                    <p>Transmitiendo ahora</p>
                    <div className="tv__player__iframe">
                    <iframe 
                        src={`https://www.youtube.com/embed/${selected}?modestbranding=1&rel=0&showinfo=0&fs=1&cc_load_policy=1` /* || "https://www.youtube.com/embed/YmCOsDw85zk" */}
                        frameborder="0"
                        allowFullScreen
                        title="demoqrata TV"
                        width="100%"
                        height="400px" >
                    </iframe>
                    <div className="tv__player__details">
                    {creation.filter(vid => vid?.['yt:videoId'].includes(`${selected}`)).map(newData => (
                        <div className="tv__player__details__content">
                        <p className="tv__player__details__content__author">{newData?.author?.name}</p>
                        <h2>{newData.title}</h2>
                        <p className="tv__player__details__content__description"> {newData?.['media:group']?.['media:description']} </p>
                        <p className="tv__player__details__content__stats">
                        {newData?.published.substring(0, 10)} {" "} • {" "}
                        <ThumbUpSharp />{" "}
                        {newData?.['media:group']?.['media:community']?.['media:starRating']?.['$']?.count}</p>
                        </div>
                    ))}
                    </div>
                    </div>
                </div>
                <div className="tv__videolist">
                    <p>A continuación...</p>
                <div className="tv__videolist__container">
                {isLoading ? (
                <Skeleton type="feed" />
                ) : (
                creation.map((video, key) => (
                    <div className = {`tv__videolist__poster__props`} key={video.id}>
                        <img 
                            key={video.id}
                            id="valor"
                            onClick={(e) => setSelected(video?.['yt:videoId'])}
                            className= {`tv__videolist__poster`}
                            src={`${base_url}${video?.['yt:videoId']}${end_url}`} alt={video.title}
                        />
                        <p className= {`tv__videolist__poster__author`}>{video?.author?.name}</p>
                    
                        <div className = {`tv__videolist__poster__details`}>
                            <h2 className= {`tv__videolist__poster__title`}>{video.title}</h2>
                            <p className= {`tv__videolist__poster__stats`} >
                            {video?.published.substring(0, 10)} {" "} • {" "}
                            <ThumbUpSharp />{" "}
                            {video?.['media:group']?.['media:community']?.['media:starRating']?.['$']?.count}</p>
                        </div>
                    </div>
                    ))
                    )}
                </div>
            </div>
            </div>
            <Footer />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </div>
    )
};

export default TvScreen;
