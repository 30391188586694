import React, { useEffect, useState } from 'react';
import "./CategoriesResults.css";
import axios from "../axios";
import axiosCancelToken from "axios";
import Skeleton from "./skeleton/Skeleton";
import YouTube from 'react-youtube';
import { Link as ScrollVideo } from "react-scroll";

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

function CategoriesResults( { selectedOption } ) {

    let cancelToken;

    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [trailerUrl, setTrailerUrl] = useState(false);
    const [videoKey, setVideoKey] = useState("");

    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Canceling the previous req.")
    }

    cancelToken = axiosCancelToken.CancelToken.source();

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const request = await axios.get(selectedOption, {cancelToken: cancelToken.token});
            setVideos(request.data);
            setIsLoading(false);
            return request;
        };
        fetchData();
    }, [selectedOption]);

    const handleImage = (e) => {
        setVideoKey(e.target.id);
        setTrailerUrl(true);
    };

    const opts = {
        playerVars: {
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };

    /* useEffect(() => {
        async function fetchData() {
            const request = await axios.get(requests.fetchTodo);
            setCreation(
                (request?.data.sort(function(x, y){
                    return Date.parse(x.published) - Date.parse(y.published);
                }).slice(request?.data.length-100)).reverse()
            );
            return request.data;
       }
       fetchData();
    }, []); */

    return (
        <div style={{ display:"flex", flexDirection:"column" }} >
            <div className="categoriesResults__player" id='playerVideo'>
                {trailerUrl && <YouTube className="categoriesResults__player__yt" videoId ={videoKey} opts={opts} />}
            </div>

        <div className="categoriesResults">
            {isLoading ? (
            <Skeleton type="feed" />
            ) : (
            videos.map((video) => (
                <div className="playerVideo__videoCard" id="categoryBox" >
            <ScrollVideo to="playerVideo" offset={-300} smooth={true} delay={300} style={{ textDecoration: 'none' }}>
            <img className="playerVideo__videoCard__img" 
                id={video.videoId}
                key={video.id}
                onClick={handleImage}
                src={`${base_url}${video?.videoId}${end_url}`}  alt={video.title}
            />
            </ScrollVideo>

            <p className="playerVideo__videoCard__author">
                {video?.authorname}
            </p>

            {video?.chapternumber
            ? (<h2 className="playerVideo__videoCard__title">{video.title} cap. #{video?.chapternumber}</h2>)
            : (<h2 className="playerVideo__videoCard__title">{video.title}</h2>)
            }
            
            {/* <p className="playerVideo__videoCard__stats">
                {video?.published.substring(0, 10)}
            </p> */}
            {/* <div>
            <p className="playerVideo__videoCard__description">
            {video.description}
            </p>
            </div> */}
            </div>
            ))
            )}

            {/* {isLoading ? (
            <Skeleton type="feed" />
            ) : (
            creation
            .filter((video) => 
                selectedOption.includes(video?.channelId)
            )
            .map((video) => (
                <VideoCard key={video.id} video={video} />
            ))
            )} */}
        </div>
    </div>
    );
}

export default CategoriesResults;