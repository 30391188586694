import React, { useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import "./SignInScreen.css";
import "./NewUserScreen.css";
/* import NavProfile from "../../components/NavProfile"; */
/* import FooterLinks from "../../components/Footer/FooterLinks";
import Social from "../../components/Footer/SocialLinks"; */
import Copyright from "../../components/Footer/Copyright";
import { auth } from "../../firebase.js";
import GoToTop from '../../GoToTop';
import {Helmet} from "react-helmet";
import Head from "next/head";
import Reel from "../../assets/peru2022res1280.mp4";
import logo from "../../assets/Logo-demoqrata.svg";

function SignInScreen() {
    const [ status, setStatus ] = useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const history = useHistory();

    const register = (e) => {
        e.preventDefault();

        auth
            .createUserWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                history.push("/home")
            })
            .catch((error) => {
                alert(error.message);
            });
    }

    const gotoSignin = (e) => {
        e.preventDefault();
        setStatus(true);
        /* history.push("/signin"); */
    }

    const signIn = (e) => {
        e.preventDefault();

        auth
            .signInWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                console.log(authUser);
            })
            .catch((error) => {
                alert(error.message);
            });
        
        history.push("/tv");
    };

    const gotoSignup = (e) => {
        e.preventDefault();
        setStatus(false);
        /* history.push("/signup"); */
    }

    const gotoReset = (e) => {
        e.preventDefault();
        history.push("/reset");
    }

    const gotoHome = (e) => {
        e.preventDefault();
        history.push("/");
    }

  return (
    <main>

        <Head>
            <title>Inicio de sesión : DEMOQRATA</title>
            <meta 
            name='description'
            content="Inicio de sesión al servicio de entretenimiento e información periodística de DEMOQRATA."
            />
        </Head>

        <Helmet>
            <title>Inicio de sesión : DEMOQRATA</title>
            <meta 
            name='description'
            content="Inicio de sesión al servicio de entretenimiento e información periodística de DEMOQRATA."
            />
            <meta 
            name='image' 
            content="https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fdemoqrata_icons_background.webp?alt=media&token=996472e3-59df-4972-91a2-b201329d009c"
            />
        </Helmet>

        {/* <NavProfile /> */}

        <header className="signin__banner">

            <video autoPlay loop muted
                style={{
                    position: "absolute",
                    zIndex: "-1",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundPosition: "center center",
                    opacity: "0.7",
                    backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, #00001b, transparent)`,
                }}>
                <source src={Reel} type='video/mp4' />
            </video>

            {status
            ? (
            <div className="signin__signin">

            <div className="signin__signin__msg">
                <p>Inicia sesión en</p>
                <img
                    src={logo}
                    alt="demoqrata"
                    onClick={gotoHome}
                />
            </div>

            <form >
                <input  ref={emailRef} type="email" placeholder="email" id="email" />

                <input ref={passwordRef} type="Password" placeholder="password" id="password" />

                <button 
                type="submit" onClick={signIn}>
                    Inicia sesión
                </button>
            </form>

            <div className="signin__signin__help">
                <div className="signin__help__signin">
                    <p>¿Olvidaste tu password?</p>
                    <span
                    onClick={gotoReset}
                    >
                    Recupéralo
                    </span>
                </div>

                <div className="signin__help__signin">
                    <p >¿Aún no tienes cuenta?</p>
                    <span 
                    onClick={gotoSignup}
                    >Crea tu cuenta aquí</span>
                </div>
            </div>

            </div>
            )
            : (
            <div className="newuser__signup">

            <div className="newuser__signup__msg">
                <p>Regístrate en</p>
                <img
                    src={logo}
                    alt="demoqrata"
                    onClick={gotoHome}
                />
            </div>

            <form >
                <input  ref={emailRef} type="email" placeholder="email" id="email" />

                <input ref={passwordRef} type="Password" placeholder="password" id="password" />

                <button 
                type="submit" onClick={register}>
                    Crear usuario
                </button>
            </form>

            <div className="newuser__signup__help">
            <p >¿Ya tienes cuenta?</p>
            <span
                onClick={gotoSignin}
            >Inicia sesión aquí</span>
            </div>

            </div>
            )}

            
            
        </header>
        
        {/* <Social />
        <FooterLinks /> */}
        
        <Copyright />
        <GoToTop />
    </main>
  )
}

export default SignInScreen;