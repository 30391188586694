import React from 'react';
import "./Copyright.css";

function Copyright() {
    return (
        
    <div className="copyright">
        <p>&#123; demoqrata 2022-2024 &#9679; Plataforma de producción y transmisión de contenido periodístico entretenido. &#x7D;</p>
        <p>&#123; Una empresa de Unicorn-ia ventures &#9679; representada legalmente en Latinoamérica por Insighter del Perú S.A.C. &#9679; RUC N°20536307401&#x7D;</p>
    </div>
        
    )
}

export default Copyright;
