import React from 'react';
import './HomeScreen.css';
import { useHistory } from 'react-router-dom';
import Banner from '../components/Banner';
import GoToTop from '../GoToTop';
import {Helmet} from "react-helmet";
import Head from "next/head";

function HomeScreen() {
  
  const history = useHistory()

  const handleSignupButton = (e) => {
    e.preventDefault();
    history.push("/signin")
  }

  return (
    <main className="homeScreen">

      <Head>
        <title>DEMOQRATA TV: sólo la verdad.</title>
        <meta 
        name='description'
        content="Inicio de sesión al servicio de entretenimiento e información periodística de DEMOQRATA."
        />
      </Head>

      <Helmet>
        <title>DEMOQRATA TV: sólo la verdad.</title>
        <meta 
        name='description'
        content="Inicio de sesión al servicio de entretenimiento e información periodística de DEMOQRATA."
        />
        <meta 
        name='image' 
        content="https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fdemoqrata_icons_background.webp?alt=media&token=996472e3-59df-4972-91a2-b201329d009c"
        />
      </Helmet>

      {/* <Headline /> */}
      {/* <Nav /> */}
      <Banner />
      {/* <RowSubidos 
        title="Subidos recientemente" 
        isLargeRow={true}
      />
      <RowVistos title="Los más vistos" isLargeRow={true} />
      <RowValorados title="Los más valorados" isLargeRow={true} />
      <CreadoresRow />
      <Footer /> */}
      {/* <NavBottom /> */}

      {/* <Copyright /> */}
      <GoToTop />

      <div className='homescreen__navbottom'>
        <div className='homescreen__navbottom__box'>
          <h3 className='homescreen__navbottom__msg'>Arma tu programación</h3>
          <button
          onClick={handleSignupButton}
          className='homescreen__navbottom__button'>Regístrate</button>
        </div>
      </div>

    </main>
  );
}
console.log();

export default HomeScreen;
