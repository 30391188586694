import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useErrorBoundary } from './components/ErrorBoundary';
/* import { DataContext } from './context/DataContext'; */
import { SearchContext } from './context/SearchContext';
import { KeywordsContext } from './context/KeywordsContext';
import axios from "./axios";
import requests from "./requests";
import './App.css';
import firebaseApp from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NewUserScreen from './screens/landing/NewUserScreen';
import SignInScreen from './screens/landing/SignInScreen';
import ResetPasswordScreen from './screens/landing/ResetPasswordScreen';
import HomeScreen from './screens/HomeScreen';
import TvScreen from './screens/TvScreen';
import Categories from './screens/CategoriesScreen';
import Creators from './screens/CreatorScreen';
import Search from './screens/SearchScreen';
import NoSearch from './screens/NoSearchScreen';
import ProfileScreen from './screens/ProfileScreen';
import PersonalizeScreen from './screens/PersonalizeScreen';
import DonationScreen from './screens/DonationScreen';
import VideoScreen from './screens/VideoScreen';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import preload from "./assets/demoqrata-preload6full.gif";

/* const BATCH_SIZE = 2;
const REQUEST_INTERVAL = 2000; */
/* var todayDay = new Date();
var todayNumber = new Date(todayDay).getDay(); */

const auth = getAuth(firebaseApp);
const HomeLoad = lazy(() => import('./screens/HomeScreen'))
const TvLoad = lazy(() => import('./screens/TvScreen'))
const SigninLoad = lazy(() => import('./screens/landing/SignInScreen'))
const ResetLoad = lazy(() => import('./screens/landing/ResetPasswordScreen'))
const NewUserLoad = lazy(() => import('./screens/landing/NewUserScreen'))
const CategoriesLoad = lazy(() => import('./screens/CategoriesScreen'))
const SearchLoad = lazy(() => import('./screens/SearchScreen'))
const CreatorLoad = lazy(() => import('./screens/CreatorScreen'))
const ProfileLoad = lazy(() => import('./screens/ProfileScreen'))
const PersonalizeLoad = lazy(() => import('./screens/PersonalizeScreen'))
const VideoLoad = lazy(() => import('./screens/VideoScreen'))
const ErrorLoad = lazy(() => import('./screens/ErrorScreen'))

function App() {

  const { ErrorBoundary, ErrorPage } = useErrorBoundary();
  /* const [data, setData] = useState([]); */
  const [inputSearch, setInputSearch] = useState('');
  const [words, setWords] = useState([]);
  const [usuarioGlobal, setUsuarioGlobal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  /* const TIMEOUT = 120000; */ // 120 seconds

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(requests.fetchLoader);
      setIsLoading(true);
      return request.data;
    }
    fetchData();
  }, []);

  //Data
  /* useEffect(() => {

    if (todayNumber === parseInt(6) || todayNumber === parseInt(0)) {
      const urls = [
        `https://dmq-api.onrender.com${requests.fetchFamilySD}`,
        `https://dmq-api.onrender.com${requests.fetchNews}`,
        `https://dmq-api.onrender.com${requests.fetchCartoon}`,
        `https://dmq-api.onrender.com${requests.fetchPeliculas}`,
        `https://dmq-api.onrender.com${requests.fetchComedia}`,
      ];

      const fetchData1 = async () => {
        const batches = [];
        for (let i = 0; i < urls.length; i += BATCH_SIZE) {
          const batch = urls.slice(i, i + BATCH_SIZE);
          batches.push(batch);
        }
  
        const results = [];
        for (let i = 0; i < batches.length; i++) {
          const batch = batches[i];
          const batchResults = await Promise.race([
            Promise.all(batch.map(url => fetch(url).then(res => res.json()))),
            new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), TIMEOUT))
          ]);
          results.push(...batchResults);
          await new Promise((resolve) => setTimeout(resolve, REQUEST_INTERVAL));
        }
  
        setData(results);
        console.log("data1:", results);
        setIsLoading(true);
      };
      fetchData1();

    } else {
      const urls = [
        `https://dmq-api.onrender.com${requests.fetchFamilyLV}`,
        `https://dmq-api.onrender.com${requests.fetchNews}`,
        `https://dmq-api.onrender.com${requests.fetchCartoon}`,
        `https://dmq-api.onrender.com${requests.fetchPeliculas}`,
        `https://dmq-api.onrender.com${requests.fetchComedia}`,
      ];

      const fetchData2 = async () => {
        const batches = [];
        for (let i = 0; i < urls.length; i += BATCH_SIZE) {
          const batch = urls.slice(i, i + BATCH_SIZE);
          batches.push(batch);
        }
  
        const results = [];
        for (let i = 0; i < batches.length; i++) {
          const batch = batches[i];
          const batchResults = await Promise.race([
            Promise.all(batch.map(url => fetch(url).then(res => res.json()))),
            new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), TIMEOUT))
          ]);
          results.push(...batchResults);
          await new Promise((resolve) => setTimeout(resolve, REQUEST_INTERVAL));
        }
  
        setData(results);
        console.log("data2:", results);
        setIsLoading(true);
      };
      fetchData2();
    }
  }, []); */

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      //código en caso de que haya sesión iniciada.
      setUsuarioGlobal(usuarioFirebase);
    } else {
      //código en caso de que no haya sesión iniciada
      setUsuarioGlobal(null);
    }
  });

  function Helpdesk() {
    window.location.href = 'https://help.demoqrata.tv/';
    return null;
  }

  return (
    /* <DataContext.Provider value={[data]}> */
    <KeywordsContext.Provider value={[words, setWords]}>
      <SearchContext.Provider value={[inputSearch, setInputSearch]}>

        <div className="app">
        <ErrorBoundary>
          {isLoading /* && data !== [] */
            ? (
              <Router onUpdate={() => window.scrollTo(0, 0)}>
                {!usuarioGlobal ? (
                  <Switch>

                    <Route component={ErrorLoad} exact path="/error">
                      <ErrorPage />
                    </Route>

                    <Route component={NewUserLoad} exact path="/signup">
                      <NewUserScreen />
                    </Route>

                    <Route component={ResetLoad} exact path="/reset">
                      <ResetPasswordScreen />
                    </Route>

                    <Route component={SigninLoad} exact path="/signin">
                      <SignInScreen />
                    </Route>

                    <Route component={HomeLoad} exact path="/">
                      <HomeScreen />
                    </Route>

                    <Route exact path="/tv">
                      {usuarioGlobal ? <Redirect to="/tv" /> : <HomeScreen />}
                    </Route >

                  </Switch>
                ) : (
                  <Suspense fallback={<h1>Cargando noticias...</h1>}>
                    <Switch>
                      <Route exact path="/">
                        {usuarioGlobal ? <Redirect to="/tv" /> : <HomeScreen />}
                      </Route >
                      <Route component={TvLoad} exact path="/tv">
                        <TvScreen correoUsuario={usuarioGlobal.email} />
                      </Route>
                      <Route component={SearchLoad} path="/search/:searchTerm">
                        <Search />
                      </Route>
                      <Route path="/search">
                        <NoSearch />
                      </Route>
                      <Route component={CategoriesLoad} path="/categories">
                        <Categories />
                      </Route>
                      <Route component={CreatorLoad} path="/creators/:creatorId" exact>
                        <Creators />
                      </Route>
                      <Route component={ProfileLoad} path="/profile" exact>
                        <ProfileScreen />
                      </Route>
                      <Route component={PersonalizeLoad} path="/personalize" exact>
                        <PersonalizeScreen />
                      </Route>
                      <Route path="/donation" exact>
                        <DonationScreen />
                      </Route>
                      <Route component={VideoLoad} path="/videoteca">
                        <VideoScreen />
                      </Route>
                      <Route component={ErrorLoad} exact path="/error">
                        <ErrorPage />
                      </Route>
                      <Route exact path="/ayuda">
                        <Helpdesk />
                      </Route>
                    </Switch>
                  </Suspense>
                )}
              </Router>
            )
            : (
              <div className="app__preload">

                <img
                  className="app__preload__img"
                  src={preload}
                  alt="Cargando noticias"
                />

              </div>
            )
          }
        </ErrorBoundary>
        </div>
      </SearchContext.Provider>
    </KeywordsContext.Provider>
    /* </DataContext.Provider> */
  );
}

export default App;