import React, { /* useState,  */useContext } from 'react';
import { SearchContext } from '../context/SearchContext';
import "./SearchScreen.css";
import Nav from "../components/Nav";
//import Sidebar from "../components/Sidebar";
import RecommendedVideos from "../components/RecommendedVideos";
import Headline from "../components/Headline";
import Footer from "../components/Footer/Footer";
import Copyright from "../components/Footer/Copyright";
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';

function SearchScreen() {
    //const [inputSearch, setInputSearch] = useState('');
    const [inputSearch, setInputSearch] = useContext(SearchContext);

    return (
        <div>
            <Headline />
            <Nav />
            <div className="NoSearch__msg"><p style= {{color: "white"}}>Toda gran búsqueda empieza con una palabra. Por favor ingresa una para empezar. Mientras tanto disfruta de todo nuestro contenido:</p></div>
            <div className="search__container">
                {/* <Sidebar /> */}
                <RecommendedVideos  setInputSearch="2021" />
            </div>
            <Footer />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </div>
    )
}

export default SearchScreen;
