import React, { useEffect, useState/* , useContext */ } from 'react';
/* import { ProfileContext } from '../context/ProfileContext'; */
/* import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; */
import "./ProfileScreen.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer/Footer";
import Copyright from "../components/Footer/Copyright";
/* import Headline from "../components/Headline"; */
/* import NavBottom from "../components/NavBottom"; */
import GoToTop from '../GoToTop';
import EditSharpIcon from '@material-ui/icons/EditSharp';
/* import Column from '../components/Column';
import requests from '../requests'; */
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';
import { serverTimestamp } from "firebase/firestore";

function ProfileScreen() {
    
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const db = firebaseApp.firestore();
    
    const [ datauser, setDatauser ] = useState();
    /* const [ dataname, setDataname ] = useContext(ProfileContext);*/
    const [ imguser, setImguser ] = useState("");
    const [ firstname, setFirstname ] = useState();
    const [ lastname, setLastname ] = useState();
    const [ phone, setPhone ] = useState();
    const [ birthdate, setBirthdate ] = useState();
    const [ country, setCountry ] = useState();

    const getfirstname = (e) => {
        setFirstname(e.target.value)
    }

    const getlastname = (e) => {
        setLastname(e.target.value)
    }

    const getphone = (e) => {
        setPhone(e.target.value)
    }

    const getbirthdate = (e) => {
        setBirthdate(e.target.value)
    }

    const getcountry = (e) => {
        setCountry(e.target.value)
    }

    //cargar foto de perfil
    /* let img = document.getElementById('img'); */
    let file = {};

    function chooseFile(e) {
        file = e.target.files[0];
    }

    async function imgButtonPressed() {
        var storage = firebaseApp.storage();
        var storageRef = storage.ref();
        await storageRef.child('users/' + user.uid + '/profile.jpg').put(file)
        .then(function() {
            window.location.reload(false);
            //alert('Hemos cargado su foto de perfil');
        }).catch(error => {
            console.log(error.message);
        })
    }

    auth.onAuthStateChanged(userStatus => {
        if (userStatus) {
            var storage = firebaseApp.storage();
            var storageRef = storage.ref();
            storageRef.child('users/' + user.uid + '/profile.jpg').getDownloadURL()
            .then(imgUrl => {
                /* img.src = imgUrl; */
                setImguser(imgUrl);
            }).catch(error => {
                console.log(error.message);
        })
        }
    })

    //ingresar y actualizar datos personales.
    const setData = async (e) => {
        e.preventDefault();
        try {
            /* const {uid} = user.uid; */
            const userDmq = {
                firstname: firstname,
                lastname: lastname,
                phone: phone,
                birthdate: birthdate,
                country: country,
                email: user.email,
                user_id: user.uid,
                timestamp: serverTimestamp(),
            };
            await db.collection('users').doc(user.uid).set(userDmq);
            window.location.reload(false);
          } catch(err) {
            console.log(err);
          }
    }

    //visualizar los datos ingresados.
    const getData = async () => {
        try {
          const documentSnapshot = await db
            .collection('users')
            .doc(user.uid)
            .get();
    
          const userData = documentSnapshot.data();
          setDatauser(userData);
          /* setDataname([...userData]); */
        } catch(err) {
            console.log(err);
        }
      };
          // Get user on mount
      useEffect(() => {
        getData();
      }, []);

    return (
        <div className="profile">
            {/* <Headline /> */}
            <Nav />
            <div className="profile__container">
                <div className="profile__data">
                    <p>Datos personales</p>
                    <form className="profile__data__img">
                        {/* <img src= "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2FDMQbrand_square.jpg?alt=media&token=5cd75f67-2b29-4a68-801b-ca45a72715e2" id="imagen" width="200px" height="200px" style={{borderRadius:"50%", padding:"10px",}} alt={datauser && datauser.firstname} /> */}
                        <img 
                        src= {imguser} 
                        id="imagen" 
                        width="200px" 
                        height="200px" 
                        style={{borderRadius:"50%", padding:"10px",}} 
                        alt="" /* {datauser && datauser.firstname} */
                        onError={(e)=>{e.target.onerror = null; e.target.src="https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2FDMQbrand_square.jpg?alt=media&token=1eb20f9a-156b-4f1a-9cb9-1e56a5605b12"}} />

                        <label for="inputTag">
                            <input id="inputTag" type="file"
                            style={{ color:"white", textAlign:"center", justifyContent:"center" }}
                            onChange={chooseFile} />
                        </label>

                        <button onClick={imgButtonPressed} >Cargar foto de perfil</button>
                        <p>email: {user.email}</p>
                        <p>Id: {user.uid}</p>
                        {/* <p>Password: {user.pword}</p> */}
                    </form>
                    <form key={ user.uid } className="profile__data__updated">
                    <ul>
                        <li>
                            <p>{datauser && datauser.firstname}</p>
                            <input onChange={getfirstname} id="firstname" type="text" placeholder="Nombre"></input>
                            <EditSharpIcon style={{size:50}} />
                        </li>
                        
                        <li>
                            <p>{datauser && datauser.lastname}</p>
                            <input onChange={getlastname} id="lastname" type="text" placeholder="Apellido(s)"></input>
                            <EditSharpIcon />
                        </li>
                        <li>
                            <p>{datauser && datauser.phone}</p>
                            <input onChange={getphone} id="phonenumber" type="tel" placeholder="Teléfono"></input>
                            <EditSharpIcon />
                        </li>
                        <li>
                            <p>{datauser && datauser.birthdate}</p>
                            <input onChange={getbirthdate} id="birthdate" type="date" placeholder="Fecha de nacimiento"></input>
                            <EditSharpIcon />
                        </li>
                        <li>
                            <p>{datauser && datauser.country}</p>
                            <input onChange={getcountry} id="country" type="text" placeholder="País de residencia(s)"></input>
                            <EditSharpIcon />
                        </li>
                        <p style={{ fontSize:'small', fontWeight:400, padding:'20px 0', }}>Por favor ingrese todas las casillas con información nueva o confirme la existente. </p>
                        <button id={ user.uid } onClick={ setData } type="submit">Actualizar mis datos</button>
                    </ul>
                    </form>
                </div>

                {/* <div className="profile__interestingvideos">
                <Column 
                    title={"Programas que te interesan"}
                    isLargeRow={true}
                />
                </div> */}

                {/* <div className="profile__donation">
                <p>Ayúdanos a crecer</p>
                <div className="profile__donation__content">
                    <p>Nos esforzamos por personalizar el contenido que más te gusta. Si te gusta lo que hacemos invítanos un café:</p>
                </div>
                <Tabs className="profile__donation__opt" defaultIndex={0}>
                    <TabList>
                        <Tab>Yapeanos</Tab>
                        <Tab>Depositanos</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="profile__donation__img">
                        <img src="https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fdemoqrata_donacionYAPE_cut.png?alt=media&token=6fe5e692-f694-43be-a4a2-0d2341f87c1c" alt="Yapea al: +51 942 137 078"/>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <p style={{paddingTop:"10px",}}>Banco de Crédito del Perú BCP</p>
                        <p>Cuenta de ahorros en soles</p>
                        <p style={{fontSize:"1.2rem", padding:"10px 0",}} >N° 194-97709184-0-42 / CCI N° 00219419770918404299</p>
                        <p>SWIFT code: BCPLPEPL</p>
                        <p>Titular: INSIGHTER DEL PERU S.A.C.</p>
                        <p>RUC N° 20536307401</p>
                    </TabPanel>
                </Tabs>
                
                </div> */}
            </div>
            <Footer />
            <Copyright />
            <GoToTop />
        </div>
    )
}

export default ProfileScreen;