import React from "react";
import { ListItem, ListItemText, Button } from "@material-ui/core";
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import { doc, deleteDoc } from "firebase/firestore";

export default function KeywordListItem({ keyword, id }) {

    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const db = firebaseApp.firestore();

    function deleteKeyword() {
        //deleteDoc(doc(db, "keywords", "id"));
        db.collection('keywords').doc(id).delete();
    }

    return (
        <div style={{ display: "flex" }}>
        <ListItem >
            <ListItemText
            primary={keyword}
            />
        </ListItem>
        
        <Button style={{color:"white",}} onClick={deleteKeyword}>X</Button>
        </div>
    );
}