import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import "./CreatorScreen.css";
import Nav from "../components/Nav";
import creationJson from "../creatorList.json";
import YouTube from 'react-youtube';
import movieTrailer from "movie-trailer";
import Skeleton from "../components/skeleton/Skeleton";
import { NavigateBeforeSharp, NavigateNextSharp, ThumbUpSharp } from "@material-ui/icons";
import CreadoresUpdate from '../components/CreadoresUpdate';
import Footer from "../components/Footer/Footer";
import Copyright from "../components/Footer/Copyright";
/* import Headline from "../components/Headline"; */
import NavBottom from "../components/NavBottom";
import GoToTop from '../GoToTop';
import { creator } from "../assets/creator";

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

function CreatorScreen({ title, fetchUrl, isLargeRow }) {

    const [videos, setVideos] = useState([]);
    const { creatorId } = useParams();
    //const paramsString = JSON.stringify(creatorId);
    const [scrollX, setScrollX] = useState(0); //-50
    const [isLoading, setIsLoading] = useState(false);
    const [trailerUrl, setTrailerUrl] = useState("");
    
    useEffect(() => {
        async function fetchData() {
            setVideos(
                (creator.sort(function(x, y){
                    return Date.parse(x.published) - Date.parse(y.published);
                }).slice(creator.length-50)).reverse()
           );
           return creator;
        }
        fetchData();
    }, []);

    const opts = {
        height: "400",
        width: "100%",
        playerVars: {
            //https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };

    const handleClick = (video) => {
        if (trailerUrl) {
            setTrailerUrl("");
        } else {
            movieTrailer(video?.title || "demoqrata en vivo")
                .then((url) => {
                    const urlParams = new URLSearchParams(new URL(video?.link?.['$']?.href).search);
                    setTrailerUrl(urlParams.get("v"));
                })
                .catch((error) => console.log(error));
        }
    };

    const handleLeftArrow = () => {
        let x = scrollX + Math.round(window.innerWidth / 2);
        if(x > 0) {
            x=0;
        }
        setScrollX(x);
    }

    const handleRightArrow = () => {
        let x = scrollX - Math.round(window.innerWidth / 2);
        let listW = videos.length * 420;
        if ((window.innerWidth - listW) > x) {
            x = (window.innerWidth - listW) - 60;
        }
        setScrollX(x);
    }

    return (
        <div>
            {/* <Headline /> */}
            <Nav />
            <div className="creatorscreen__container">
                {creationJson.filter((poster) => 
                poster.canalId.includes(creatorId)
                ).map((poster) => (

                <div className = {`creatorscreen__poster`}>
                    <img 
                    key={poster.id}
                    id={poster.canalId}
                    className= {`creatorscreen__poster__item`}
                    src={poster.canalPosterUrl} 
                    title={poster.canalCategoria}
                    alt={poster.nombreCanal}
                />
                </div>

               ))}

            <div className="creatorscreen__videos">

            <header className="creatorscreen__banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, black, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2FFondo_screen04.jpg?alt=media&token=82dce8c5-127a-47ac-811a-ca9869f02652"
                )`, 
                backgroundPosition: "center center",
            }}>

            <div className="creatorscreen__videos__player">
            {trailerUrl && <YouTube videoId ={trailerUrl} opts={opts} />}
            </div>

            <div className="creatorscreen__videos__row">
                <div className="creatorscreen__videos__msg">
                    <h2>Elige el video que prefieras ver aquí:</h2>
                </div>
                <div className="row__poster__left" onClick={handleLeftArrow}>
                < NavigateBeforeSharp style={{fontSize: 50}} />
                </div>
                <div className="row__poster__right" onClick={handleRightArrow}>
                < NavigateNextSharp style={{fontSize: 50}} />
                </div>

                <div className="creatorscreen__videos__item" style={{marginLeft: scrollX/* , width: creation.length * 150 */}}>

                {isLoading ? (
                <Skeleton type="feed" />
                ) : (
                videos.filter((video) => 
                (video?.['yt:channelId']).includes(creatorId)

                ).map((video, key) => (

                <div className = {`row__creator__props`} key={video.id}>
                    
                    <img 
                        key={video.id}
                        onClick={() => handleClick(video)}
                        className= {`row__poster`}
                        src={`${base_url}${isLargeRow ? video?.['yt:videoId'] : video?.['yt:videoId']}${end_url}`} alt={video.title}
                    />
                    <p className= {`row__poster__author`}>{video?.author?.name}</p>
                
                    <div className = {`row__poster__details`}>
                        <h2 className= {`row__poster__title`}>{video.title}</h2>
                        <p className= {`row__poster__stats`} >
                        {video?.published.substring(0, 10)} {" "} • {" "}
                        <ThumbUpSharp />{" "}
                        {video?.['media:group']?.['media:community']?.['media:starRating']?.['$']?.count}</p>
                    </div>

                </div>

                ))
                )}
                </div>
                
            </div>
            </header>
            </div>
            </div>
            <CreadoresUpdate />
            <Footer />
            <Copyright />
            <GoToTop />
            <NavBottom />
        </div>
    )
}
export default CreatorScreen;