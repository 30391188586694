export const preloadgif =
[
{
	"id": 1,
	"urlgif":"https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif1.gif?alt=media&token=d8c4bd09-4784-477b-b025-cbedf1b94b70"
},
{
	"id": 2,
	"urlgif":"https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif2.gif?alt=media&token=5eb45024-9fe7-44fd-a640-8bcaf3d04558"
},
{
	"id": 3,
	"urlgif":"https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif3.gif?alt=media&token=8edc6e5c-9701-4c7d-8bcc-aa3f2f7487d8"
},
{
	"id": 4,
	"urlgif":"https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif4.gif?alt=media&token=0df51864-9fe2-4f73-8c1e-23d09f690523"
},
{
	"id": 5,
	"urlgif":"https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif5.gif?alt=media&token=f483d47d-c2a6-4656-9abf-314bc6a00a26"
},
{
	"id": 6,
	"urlgif":"https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif6.gif?alt=media&token=e22b8383-0be0-403e-9cc8-53dc77ccf119"
},
{
	"id": 7,
	"urlgif":"https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif7.gif?alt=media&token=ace4453c-fe1c-4105-9584-fa52e1ba08a3"
},
{
	"id": 8,
	"urlgif":"https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif8.gif?alt=media&token=19cd2bee-6cd1-4ca9-8139-acaccf3c1f83"
}
]