import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import "./PaypalBox.css";

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

function PaypalBox() {
    const [donation, setDonation] = useState(0)
    const createOrder = (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: donation
              }
            }
          ]
        });
      };

    const onApprove = (data, actions) => {
        return actions.order.capture();
    };
    function handleChange(e) {
      setDonation(e.target.value);
    }

    return (
        <div className="paypalbox">
            <div className="paypalbox__contents">
                <div className="paypalbox__contents__donation">
                    <p>Indica el monto en USD $:</p>
                    <input 
                        type="text"
                        onChange={handleChange}
                        value={donation}
                    />
                </div>
                <PayPalButton
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                />
            </div>
        </div>
    );
}

export default PaypalBox;
