import React, { useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import "./NewUserScreen.css";
/* import NavProfile from "../components/NavProfile"; */
/* import FooterLinks from "../../components/Footer/FooterLinks";
import Social from "../../components/Footer/SocialLinks"; */
import Copyright from "../../components/Footer/Copyright";
import { auth } from "../../firebase.js";
import GoToTop from '../../GoToTop';
import Reel from "../../assets/peru2022res1280.mp4";
import logo from "../../assets/Logo-demoqrata.svg";

function NewUserScreen() {

    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const history = useHistory();

    const register = (e) => {
        e.preventDefault();

        auth
            .createUserWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                history.push("/home")
            })
            .catch((error) => {
                alert(error.message);
            });
    }

    const gotoSignin = (e) => {
        e.preventDefault();
        history.push("/signin");
    }
    

  return (
    <div>
        {/* <NavProfile /> */}

        <header className="newuser__banner" >

            <video autoPlay loop muted
                style={{
                    position: "absolute",
                    zIndex: "-1",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundPosition: "center center",
                    opacity: "0.7",
                    backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, #00001b, transparent)`,
                }}>
                <source src={Reel} type='video/mp4' />
            </video>

            <div className="newuser__signup">

                <div className="newuser__signup__msg">
                    <p>Regístrate en</p>
                    <img
                        src={logo}
                        alt="demoqrata"
                    />
                </div>

                <form >
                    <input  ref={emailRef} type="email" placeholder="email" id="email" />

                    <input ref={passwordRef} type="Password" placeholder="password" id="password" />

                    <button 
                    type="submit" onClick={register}>
                        Crear usuario
                    </button>
                </form>
                
                <div className="newuser__signup__help">
                <p >¿Ya tienes cuenta?</p>
                <span
                    onClick={gotoSignin}
                >Inicia sesión aquí</span>
                </div>

            </div>
            
        </header>
        
        {/* <Social /> */}
        {/* <FooterLinks /> */}
        <Copyright />
        <GoToTop />
    </div>
  )
}

export default NewUserScreen;