import React from 'react';
import SocialLinks from './SocialLinks';
/* import FooterLinks from './FooterLinks'; */
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="box">
            <div className="socialLinks">
                <SocialLinks />
            </div>
            {/* <FooterLinks /> */}
        </footer>
    );
};

export default Footer;