import "./DropdownMenu.css";
import React, { useState, useEffect, useRef, useContext } from 'react';
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';
import { SearchContext } from '../context/SearchContext';
import { Link, useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
/* import { auth } from "../firebase"; */
/* import SearchSharpIcon from "@material-ui/icons/SearchSharp"; */

function NavItem(props) {
    const [open, setOpen] = useState(false);
  
    return (
      <li className="nav-item">
        <a /* href="#" */ className="icon-button" onClick={() => setOpen(!open)}>
          {props.icon}
        </a>
        {open && props.children}
      </li>
    );
  }

function DropdownMenu() {
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const db = firebaseApp.firestore();

    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const [inputSearch, setInputSearch] = useContext(SearchContext);
    const [search, setSearch] = useState("");
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const history = useHistory()
    const [ datauser, setDatauser ] = useState();

    const handleSearch = (e) => {
      e.preventDefault();
      setInputSearch(search);

      history.push(`/search/${search}`)
    }
  
    useEffect(() => {
      setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    }, [])
  
    /* function calcHeight(el) {
      const height = el.offsetHeight;
      setMenuHeight(height);
    } */
  
    function DropdownItem(props) {
      return (
        <a href={props.href} className="menu-item" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
          {/* <span className="icon-button">{props.leftIcon}</span> */}
          {props.children}
          {/* <span className="icon-right">{props.rightIcon}</span> */}
        </a>
      );
    }

    //visualizar los datos ingresados en el profile.
    const getData = async () => {
      try {
        const documentSnapshot = await db
          .collection('users')
          .doc(user.uid)
          .get();
  
        const userData = documentSnapshot.data();
        setDatauser(userData);
      } catch(err) {
          console.log(err);
      }
    };
        // Get user on mount
    useEffect(() => {
      getData();
    }, []);
  
    return (
      <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
  
        <CSSTransition>
          <div className="menu">

          <div className="dropdown__username">
            <p>Hola {datauser && datauser.firstname}</p>
          </div>

          {/* <div className="dropdown__search">
          <form >
            <input 
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Busca por palabra clave" 
            type="text"
            ref={inputRef}
            />
            <button 
                onClick={handleSearch}
                type="submit" 
                className="dropdown__searchButton">
                <SearchSharpIcon type="submit" />
            </button>
          </form>
          </div> */}

          <Link to="/categories" style={{ textDecoration: 'none' }}>
            <DropdownItem >Categorías</DropdownItem>
          </Link>

          <Link to="/donation" style={{ textDecoration: 'none' }}>
            <DropdownItem >Donación</DropdownItem>
          </Link>

          <Link to="/profile" style={{ textDecoration: 'none' }}>
            <DropdownItem >Perfil</DropdownItem>
          </Link>

          <a href="https://help.demoqrata.tv/" target="_blank" rel="noopener noreferrer" >
            <DropdownItem >Ir a Ayuda</DropdownItem>
          </a>

          {/* <p className="dropdown__copyright">demoqrata 2022-2023. &#9679; Plataforma de producción y transmisión de contenido periodístico veráz y entretenido.</p> */}

          {/* <Link to="/profile" style={{ textDecoration: 'none' }}>
            <DropdownItem >Mi Perfil</DropdownItem>
          </Link>

          <Link to="/personalize" style={{ textDecoration: 'none' }}>
            <DropdownItem >Personalizar mi contenido</DropdownItem>
          </Link> */}
          
          <DropdownItem>
              <p 
              style={{ cursor:"pointer" }}
              onClick={() => {
              auth.signOut();
              history.push("/");
              /* window.location.reload(); */
              }}>Salir de demoqrata</p>
          </DropdownItem>
  
          </div>
        </CSSTransition>
  
        
      </div>
    );
  }

  export {DropdownMenu, NavItem};