import React, { useState } from "react";
import "./CategoriesScreen.css";
import Nav from '../components/Nav';
import CategoriesNav from '../components/CategoriesNav';
import CategoriesResults from '../components/CategoriesResults';
import requests from "../requests";
import Footer from "../components/Footer/Footer";
import Copyright from "../components/Footer/Copyright";
/* import Headline from "../components/Headline"; */
/* import NavBottom from "../components/NavBottom"; */
import GoToTop from '../GoToTop';

function CategoriesScreen() {
    const [selectedOption, setSelectedOption] = useState(requests.fetchNews);

    return (
        <div className="categoriesScreen">
            {/* <Headline /> */}
            <Nav />
            <CategoriesNav setSelectedOption={setSelectedOption} />  
            <CategoriesResults selectedOption={selectedOption} />
            <Footer />
            <Copyright />
            <GoToTop />
            {/* <NavBottom /> */}
        </div>
    )
}

export default CategoriesScreen
