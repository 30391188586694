import React, { useEffect, useState, useContext } from 'react';
import { SearchContext } from '../context/SearchContext';
import "./RecommendedVideos.css";
import axios from "../axios";
import FlipMove from 'react-flip-move';
import requests from "../requests";
import Skeleton from "./skeleton/Skeleton";
import YouTube from 'react-youtube';
import { Link as ScrollVideo } from "react-scroll";

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

function RecommendedVideos() {

    const [inputSearch, setInputSearch] = useContext(SearchContext);
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [trailerUrl, setTrailerUrl] = useState(false);
    const [videoKey, setVideoKey] = useState("");

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const request = await axios.get(requests.fetchTodo);
            setVideos(
                (request?.data.sort(function(x, y){
                    return Date.parse(x.published) - Date.parse(y.published);
                }).slice(request?.data.length-100)).reverse()
            );
            setIsLoading(false);
            return request.data;
        };
        fetchData();
    }, []);

    const handleImage = (e) => {
        setVideoKey(e.target.id);
        setTrailerUrl(true);
    };

    const opts = {
        playerVars: {
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };
    

    return (
        <div className="recommendedVideos">

            <div className="recommendedVideos__player" id='playerVideo'>
                {trailerUrl && <YouTube className="recommendedVideos__player__yt" videoId ={videoKey} opts={opts} />}
            </div>

            <div className="recommendedVideos__msg">
                <h2>Te recomendamos ver el siguiente contenido para: # <span>{inputSearch}</span></h2>
            </div>

            <div className="recommendedVideos__videocard">
            <FlipMove>
            {isLoading ? (
            <Skeleton type="feed" />
            ) : (
            videos.filter((video, key) => 
                (video?.title?.toLowerCase() && 
                JSON.stringify(video?.description?.toLowerCase()).includes(inputSearch.toLowerCase()))
            ).map((video, key) => (
                <div key={video.id} className="playerVideo__videoCard">
            
            <ScrollVideo to="playerVideo" offset={-300} smooth={true} delay={300} style={{ textDecoration: 'none' }}>
            <img className="playerVideo__videoCard__img" 
                id={video.videoId}
                key={video.id}
                onClick={handleImage}
                src={`${base_url}${video?.videoId}${end_url}`}  alt={video.title}
            />
            </ScrollVideo>

            <p className="playerVideo__videoCard__author">
                {video?.authorname}
            </p>
            <h2 className="playerVideo__videoCard__title">{video.title}</h2>
            <p className="playerVideo__videoCard__stats">
                {video?.published.substring(0, 10)}
            </p>
            <div>
            <p className="playerVideo__videoCard__description">
            {video.description}
            </p>
            </div>
        </div>
            ))
            )}  
            </FlipMove>
            </div>
        </div>
    )
}

export default RecommendedVideos;