import React/* , { useEffect, useState } */ from 'react';
import "./DonationScreen.css";
import Nav from "../components/Nav";
import Footer from "../components/Footer/Footer";
import Copyright from "../components/Footer/Copyright";
/* import Headline from "../components/Headline"; */
import PaypalBox from "../components/PaypalBox";
/* import NavBottom from "../components/NavBottom"; */
import GoToTop from '../GoToTop';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { EmojiFoodBeverageSharp, LocalDrinkSharp, KitchenSharp } from "@material-ui/icons/";

function DonationScreen() {

    return (
        <div>
            {/* <Headline /> */}
            <Nav />
            <div className="donation__frame">
                <div className="hidden"><img src="https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fdemoqrata_donation_after_hd.png?alt=media&token=9256064b-a77e-4191-a1d8-9b1b0b9a3c0e" alt="demoqrata" /></div>
                <div className="donation__contents">
                <div className="pro__donation">
                <h2>Ayúdanos a crecer</h2>
                <div className="pro__donation__content">
                    <p>Nos esforzamos por darte lo mejor y personalizar el contenido que más te gusta. Si quieres apoyarnos invítanos un café para mantenernos despiertos:</p>
                </div>
                <div className="pro__donation__sug" >
                <ul>
                    <li>
                    <EmojiFoodBeverageSharp />
                    <p>Tómense un café instantáneo y sigan codeando: USD $1.</p>
                    </li>
                    <li>
                    <LocalDrinkSharp />
                    <p>Vamos a por un Starbucks: USD $10.</p>
                    </li>
                    <li>
                    <KitchenSharp />
                    <p>Cómprense una máquina de café, se lo merecen: USD $20.</p>
                    </li>
                </ul>
                </div>
                <Tabs className="pro__donation__opt" defaultIndex={0}>
                    <TabList >
                        {/* <Tab style={{ color:"#111" }}>Yape</Tab> */}
                        {/* <Tab style={{ color:"#111" }}>Transferencia online</Tab> */}
                        <Tab style={{ color:"#111" }}>Paypal / Crédito o Débito</Tab>
                        {/* <Tab>Crédito o Débito</Tab> */}
                    </TabList>
                    {/* <TabPanel>
                        <div className="pro__donation__img">
                        <img style={{padding:"0 10px", width:"100%"}} src="https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fdemoqrata_donacionYAPE_hor.png?alt=media&token=d8930f39-e293-4c53-bf3c-ca88c932d169" alt="Yapea al: +51 942 137 078"/>
                        </div>
                    </TabPanel> */}
                    {/* <TabPanel style={{paddingLeft:"20px",}}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fbcp.svg?alt=media&token=2436efdf-7841-4a9f-bebd-ca7f8ba43191" style={{ width:"120px", paddingTop:"10px" }} alt="BCP" />
                        <p style={{paddingTop:"10px", fontSize:"1rem", color:"#111" }}>Banco de Crédito del Perú BCP</p>
                        <p style={{ color:"#111" }}>Cuenta de ahorros en soles</p>
                        <p style={{fontSize:"1.2rem", padding:"10px 0", color:"#111" }} >N° 194-97709184-0-42 / CCI N° 00219419770918404299</p>
                        <p style={{fontSize:"1rem", color:"#111" }}>SWIFT code: BCPLPEPL</p>
                        <p style={{ color:"#111" }}>Titular: INSIGHTER DEL PERU S.A.C.</p>
                        <p style={{ color:"#111" }}>RUC N° 20536307401</p>
                    </TabPanel> */}
                    <TabPanel>
                        <PaypalBox />
                    </TabPanel>
                    {/* <TabPanel>
                        <p>Dona con Tarjeta</p>
                    </TabPanel> */}
                </Tabs>
                </div>
                </div>
            </div>
            
            <Footer />
            <Copyright />
            <GoToTop />
            {/* <NavBottom /> */}
        </div>
    )
}

export default DonationScreen;