import React from 'react';
import requests from '../requests';
import "./CategoriesNav.css";
import { Link as ScrollVideo } from "react-scroll";

function CategoriesNav( { setSelectedOption } ) {
    return (
        <ScrollVideo to="categoryBox" offset={0} smooth={true} delay={0} style={{ textDecoration: 'none' }}>
        <div className="categories__nav">
            <h2 onClick={() => setSelectedOption (requests.fetchNoticiastodo)} >Noticias</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchDocumtodo)} >Documentales</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchCocinatodo)} >Cocina</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchComediatodo)} >Comedia</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchTecnologiatodo)} >Tecnología</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchViajestodo)} >Viajes</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchMascotastodo)} >Mascotas</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchCartoontodo)} >Dibujos animados</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchNovela2todo)} >La Impostora</h2>
            <h2 title='Los ricos no piden permiso' onClick={() => setSelectedOption (requests.fetchNovela1todo)} >LRNPP</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchNovela3todo)} >Señora Acero</h2>
            <h2 title='Historias detrás de la muerte' onClick={() => setSelectedOption (requests.fetchSerie1todo)} >HDM</h2>
            <h2 onClick={() => setSelectedOption (requests.fetchPeliculastodo)} >Películas</h2>
        </div>
        </ScrollVideo>
    )
}

export default CategoriesNav;
