import React, { useState } from 'react';
import paypalLogo from "../assets/paypal.png";
import "./PaypalButton.css";

const PayPalDonation = () => {
  const [amount, setAmount] = useState(5);

  /* const handleChange = (event) => {
    setAmount(event.target.value);
  }; */

  const handleSubmit = (event) => {
    event.preventDefault();
    window.open(`https://www.paypal.com/donate?hosted_button_id=2MU6EKV4P2DAU&amount=${amount}`);
  };

  return (
    <div className='paypalbutton'>
      <h2>Donanos $5</h2>
      <div className='paypalbutton__content'>
        <form onSubmit={handleSubmit}>
            {/* <label htmlFor="donation-amount">o Indica un monto:</label>
            <input type="number" id="donation-amount" value={amount} onChange={handleChange} /> */}
            <button type="submit">
                <img
                src={paypalLogo}
                alt="Paypal"
                />
                <p>Dona aquí</p>
            </button>
        </form>
      </div>
    </div>
  );
};

export default PayPalDonation;
