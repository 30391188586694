import React, { useState, useEffect, useContext, useRef } from "react";
import { SearchContext } from '../context/SearchContext';
import "./Nav.css";
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/Logo-demoqrata.svg";
import { MenuSharp } from "@material-ui/icons";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import  { DropdownMenu, NavItem }  from "./DropdownMenu";
import iconlive from "../assets/Live_dmq.gif";

function Nav() {
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const db = firebaseApp.firestore();

    const [show, handleShow] = useState(false);
    const [inputSearch, setInputSearch] = useContext(SearchContext);
    const [ datauser, setDatauser ] = useState();
    const [search, setSearch] = useState("");
    const [ imguser, setImguser ] = useState("");
    const inputRef = useRef(null);
    const history = useHistory()

    const handleSearch = (e) => {
        e.preventDefault();
        setInputSearch(search);
        history.push(`/search/${search}`)
    }

    const handleHome = (e) => {
        e.preventDefault();
        history.push("/tv")
    }

    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            handleShow(true);
        } else {
            handleShow(false);
        }
    };

    useEffect (() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);

    //visualizar los datos ingresados en el profile.
    const getData = async () => {
        try {
          const documentSnapshot = await db
            .collection('users')
            .doc(user.uid)
            .get();
    
          const userData = documentSnapshot.data();
          setDatauser(userData);
        } catch(err) {
            console.log(err);
        }
      };
          // Get user on mount
      useEffect(() => {
        getData();
      }, []);

      // visualizar la foto de perfil desde el profileScreen.
      let img = document.getElementById('img_nav');

      auth.onAuthStateChanged(userStatus => {
        if (userStatus) {
            var storage = firebaseApp.storage();
            var storageRef = storage.ref();
            storageRef.child('users/' + user.uid + '/profile.jpg').getDownloadURL()
            .then(imgUrl_nav => {
                setImguser(imgUrl_nav);
            }).catch(error => {
                console.log(error.message);
        })
        }
    })

    return (
        <div className={`nav ${show && "nav__black"}`}>
            <div className="nav__contents">
                <Link to="/" style={{ textDecoration: 'none' }}>
                <div className='nav__logo'>
                    <img 
                    src={logo}
                    alt="demoqrata" title="made for truth"
                    />
                </div> 
                </Link>

                <div className="nav__search">
                    <form >
                        <input 
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder="Busca por palabra clave" 
                        type="text"
                        ref={inputRef}
                        />
                        <button 
                            onClick={handleSearch}
                            type="submit" 
                            className="nav__searchButton">
                            <SearchSharpIcon type="submit" />
                        </button>
                    </form>
                </div>

                <div className="nav__profile">
                    <div className="nav__profile__live">
                        <img
                            onClick={handleHome}
                            src={iconlive}
                            alt="demoqrata en vivo"
                            width="75px"
                            title="Señal en vivo"
                        />
                    </div>

                    <NavItem icon={<MenuSharp />} className="nav__profile__dropdown">
                        <DropdownMenu></DropdownMenu>
                    </NavItem>
                </div>
            </div>
        </div>
    )
}

export default Nav;