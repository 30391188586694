import React, { forwardRef, useState } from 'react';
import "./VideoCard.css";
//import TextTruncate from "react-text-truncate";
import { ThumbUpSharp } from "@material-ui/icons";
/* import YouTube from 'react-youtube';
import movieTrailer from "movie-trailer"; */

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

const VideoCard = forwardRef(({ video }, ref) => {

    /* const [trailerUrl, setTrailerUrl] = useState(""); */
    const [videoKey, setVideoKey] = useState("");
    /* const [play, setPlay] = useState(true); */

    /* const opts = {
        height: "50%",
        width: "100%",
        playerVars: {
            autoplay: 0,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        },
    };

    const handleClick = (video) => {
        if (trailerUrl) {
            setTrailerUrl("");
        } else {
            movieTrailer(video?.title || "")
                .then((url) => {
                    const urlParams = new URLSearchParams(new URL(video?.videohref).search);
                    setTrailerUrl(urlParams.get("v"));
                })
                .catch((error) => console.log(error));
        }
    }; */

    const handleImage = (e) => {
        setVideoKey(e.target.id);
        /* setPlay(true); */
    };

    return (
        <div ref={ref} className="videoCard">
            <img 
                id={video.videoId}
                key={video.id}
                onClick={handleImage}
                /* onClick={() => handleClick(video)} */
                /* className= {`row__poster ${isLargeRow && "row__posterLarge"} `} */
                src={`${base_url}${video?.videoId}${end_url}`}  alt={video.title}
            />
            {/* <div className="videoCard__player">
                {trailerUrl && <YouTube videoId ={trailerUrl} opts={opts} />}
            </div> */}
            <p className="videoCard__author">
                {video?.authorname}
            </p>
            {/* <TextTruncate 
                line={1}
                element="p"
                truncateText="..."
                text={video.description}
            /> */}
            <h2 className="videoCard__title">{video.title}</h2>
            <p className="videoCard__stats">
                {video?.published.substring(0, 10)} {" "} • {" "}
                <ThumbUpSharp />{" "}
                {video?.videoviews}
            </p>
            <div>
            <p className="videoCard__description">
            {video.description}
            </p>
            </div>
        </div>
    );
});

export default VideoCard;
