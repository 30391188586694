import React, { useState/* , useEffect */, useContext, useRef/* , forwardRef */ } from "react";
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';
import { /* Link,  */useHistory } from "react-router-dom";
import { SearchContext } from '../context/SearchContext';
import './HomeScreen.css';
/* import RowInvestigacion from '../components/RowInvestigacion';
import RowOpinion from '../components/RowOpinion';
import RowInformacion from '../components/RowInformacion';
 */import Banner from '../components/Banner';
/* import Nav from '../components/Nav';
import CreadoresRow from '../components/CreadoresRow';
import Footer from "../components/Footer/Footer"; */
/* import Copyright from "../components/Footer/Copyright"; */
/* import Headline from "../components/Headline";
import NavBottom from "../components/NavBottom"; */
import GoToTop from '../GoToTop';
import SearchSharpIcon from "@material-ui/icons/SearchSharp";

function TvScreen() {

  const auth = getAuth(firebaseApp);
  /* const user = auth.currentUser;
  const db = firebaseApp.firestore(); */

  /* const [show, handleShow] = useState(false); */
  const [inputSearch, setInputSearch] = useContext(SearchContext);
  /* const [ datauser, setDatauser ] = useState();
  const [endText, setEndText] = useState(''); */
  const [search, setSearch] = useState("");
  /* const [ dataname, setDataname ] = useContext(ProfileContext);*/
  /* const [ imguser, setImguser ] = useState(""); */
  const inputRef = useRef(null);
  const history = useHistory()

  const handleSearch = (e) => {
    e.preventDefault();
    setInputSearch(search);

    history.push(`/search/${search}`)
  }

  return (
    <div className="homeScreen">
      {/* <Headline /> */}
      {/* <Nav /> */}
      <Banner />
      {/* <RowInvestigacion 
        title="Investigación" 
        isLargeRow={true}
      />
      <RowOpinion title="Opinión" isLargeRow={true} />
      <RowInformacion title="Información" isLargeRow={true} /> */}
      {/* <CreadoresRow />
      <Footer /> */}
      {/* <NavBottom /> */}

      {/* <Copyright /> */}
      <GoToTop />

      <div className='homescreen__navbottom'>
      <div className="homescreen__search">
        <form >
            <input 
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Busca por palabra clave" 
            type="text"
            ref={inputRef}
            />
            <button 
                onClick={handleSearch}
                type="submit" 
                className="homescreen__searchButton">
                <SearchSharpIcon type="submit" />
            </button>
        </form>
      </div>
      </div>

    </div>
  );
}

export default TvScreen;
