import React, { useState, useEffect, useRef } from "react";
import firebaseApp from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import PayPalDonation from "./PaypalButton";
import ReactPlayer from 'react-player/youtube';
import { Link, useHistory } from "react-router-dom";
import axios from "../axios";
import requests from "../requests";
import { Link as ScrollVideo } from "react-scroll";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "./Banner.css";
import { adslist } from "../assets/adslist.js";
import { dmqintro } from "../assets/dmqintro.js";
import { AccessTimeSharp, ArrowDropDownSharp, NavigateBeforeSharp, NavigateNextSharp } from "@material-ui/icons";
import logo from "../assets/Logo-demoqrata.svg";
import iconsilencio from "../assets/sound-off.svg";
import iconsonido from "../assets/sound-loud.svg";
import fullscreen from "../assets/fullscreen.svg";
import playicon from "../assets/play.svg";
import pauseicon from "../assets/pause.svg";
import fromicon from "../assets/from.svg";
import profileicon from "../assets/profile.svg";
import categoryicon from "../assets/category.svg";
import ad from "../assets/ad.svg";
import help from "../assets/help.svg";
import menuvert from "../assets/menu-vertical.svg";
import closemenu from "../assets/close.svg";
import signout from "../assets/signout.svg";
import signin from "../assets/signin.svg";
import iconChannel1 from "../assets/family.png";
import iconChannel2 from "../assets/news.png";
import iconChannel3 from "../assets/kids.png";
import iconChannel4 from "../assets/cinema.png";
import iconChannel5 from "../assets/comedy.png";
import preload from "../assets/dmqGif1.gif";
import { preloadgif } from "../assets/preloadgif.js";

const auth = getAuth(firebaseApp);
const user = auth.currentUser;
const db = firebaseApp.firestore();

const base_url = "https://i2.ytimg.com/vi/";
const end_url = "/hqdefault.jpg";

const channelsOpt =
    [
        {
            "id": 1,
            "name": "Family",
            "icon": iconChannel1,
            "msg": "Este canal es apto para toda la familia."
        },
        {
            "id": 2,
            "name": "News",
            "icon": iconChannel2,
            "msg": "Este canal es apto para mayores de 14 años de edad."
        },
        {
            "id": 3,
            "name": "Kids",
            "icon": iconChannel3,
            "msg": "Este canal es apto para toda la familia."
        },
        {
            "id": 4,
            "name": "Cinema",
            "icon": iconChannel4,
            "msg": "Este canal es apto para mayores de 14 años de edad."
        },
        {
            "id": 5,
            "name": "Comedy",
            "icon": iconChannel5,
            "msg": "Este canal es apto para mayores de 14 años de edad."
        }
    ]

function Banner() {
    const [usuarioGlobal, setUsuarioGlobal] = useState(null);
    onAuthStateChanged(auth, (usuarioFirebase) => {
        if (usuarioFirebase) {
          setUsuarioGlobal(usuarioFirebase);
        } else {
          setUsuarioGlobal(null);
        }
    });
    const history = useHistory();
    const [tvWrapper, setTvWrapper] = useState();
    const [show, handleShow] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [progressAd, setProgressAd] = useState(0);
    const [durationAd, setDurationAd] = useState(0);
    const [muted, setMuted] = useState(true);
    const [volume, setVolume] = useState(0.9);
    const [play, setPlay] = useState(true);
    const [pausePrev, setPausePrev] = useState(false);
    const [arrayKey, setArrayKey] = useState(0);
    const [playingIntermediateVideo, setPlayingIntermediateVideo] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showDatavideo, setShowDatavideo] = useState(true);
    const [changeChannel, setChangeChannel] = useState(1);
    const [selectedChannel, setSelectedChannel] = useState(iconChannel1);
    const [open, setOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState(30);
    const [preloadGifImg, setPreloadGifImg] = useState([{
        "id": 1,
        "urlgif": "https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fpreload%2FdmqGif1.gif?alt=media&token=d8c4bd09-4784-477b-b025-cbedf1b94b70"
    }]);
    const [quality, setQuality] = useState('auto');
    const [showAd, setShowAd] = useState(false);
    const [arrayAds, setArrayAds] = useState([]);
    const [arrayKeyAds, setArrayKeyAds] = useState(0);
    const [internetSpeed, setInternetSpeed] = useState(0);
    const [arraySelectedIntro, setArraySelectedIntro] = useState([]);
    const [updateSelectedIntro, setUpdateSelectedIntro] = useState(false);
    const [ datauser, setDatauser ] = useState();
    const playerRef = useRef(null);
    const handle = useFullScreenHandle();
    const menuRef = useRef();
    const imgRef = useRef();
    /* var todayDay = new Date();
    var todayNumber = new Date(todayDay).getDay();
    console.log("Prev numberday:", todayNumber); */
    const date = new Date();
    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', timeZone: 'America/Lima' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    var todayNumber = new Date(formattedDate).getDay();

    //visualizar los datos ingresados en el profile.
    const getData = async () => {
        try {
          const documentSnapshot = await db
            .collection('users')
            .doc(user.uid)
            .get();
    
          const userData = documentSnapshot.data();
          setDatauser(userData);
        } catch(err) {
            console.log(err);
        }
      };
          // Get user on mount
      useEffect(() => {
        getData();
      }, []);

    const muteIconStyle = {
        filter: muted ? "invert(28%) sepia(92%) saturate(7358%) hue-rotate(1deg) brightness(98%) contrast(127%)" : "invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%)",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: '25px',
        height: '100%',
        margin: "0 10px",
    };

    const playIconStyle = {
        filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%)",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: '25px',
        height: '100%',
        margin: "0 10px",
    };

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const handleIntermediateVideoEnded = () => {
        setPlayingIntermediateVideo(false);
        setUpdateSelectedIntro(true);
        if (parseInt(arrayKey) < tvWrapper.length - 1) {
            setArrayKey(parseInt(arrayKey) + 1);
            setShowDatavideo(true);
        } else {
            setArrayKey(0);
            setShowDatavideo(true);
        };
        setPlay(true);
    };

    const handlePlayerEnded = () => {
        if (parseInt(arrayKey) < tvWrapper.length - 1) {
            setPlayingIntermediateVideo(true);
        } else {
            setArrayKey(0);
            setShowDatavideo(true);
        };
        setPlay(true);
    };

    useEffect(() => {
        async function fetchPreload() {

            setPreloadGifImg(
                preloadgif[
                Math.floor(Math.random() * preloadgif.length - 1)
                ]
            );
            return preloadgif;
        }
        fetchPreload();
    }, [changeChannel]);

    useEffect(() => {
        const timer =
            timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
        return () => clearInterval(timer);
    }, [timeLeft, changeChannel]);

    window.addEventListener("click", (e) => {
        if (e.target !== menuRef.current && e.target !== imgRef.current) {
            setOpen(false);
        }
    });

    useEffect(() => {
        const checkInternetSpeed = setInterval(() => {
            const img = new Image();
            const startTime = Date.now();
            img.onload = () => {
                const duration = Date.now() - startTime;
                const bitsLoaded = img.length * 8;
                const speedBps = (bitsLoaded / duration) * 1000;
                setInternetSpeed(speedBps);
            };
            img.src = 'https://i.imgur.com/qWLGSgP.png?cache=' + Math.random();
        }, 1000);

        return () => clearInterval(checkInternetSpeed);
    }, []);
    useEffect(() => {
        if (internetSpeed < 100000) {
            setQuality('240p');
        } else if (internetSpeed < 500000) {
            setQuality('360p');
        } else if (internetSpeed < 1000000) {
            setQuality('480p');
        } else if (internetSpeed < 2500000) {
            setQuality('720p');
        } else if (internetSpeed < 5000000) {
            setQuality('1080p');
        } else {
            setQuality('max');
        }
    }, [internetSpeed]);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
    };

    //wrapper by default
    useEffect(() => {
        if (!tvWrapper) {
            fetchWrapper();
        }
    }/* , [] */);

    const channelsMenu = [
        {
            "id": 1,
            "title": "Familia C1",
            "iconImg": iconChannel1
        },
        {
            "id": 2,
            "title": "Noticias C2",
            "iconImg": iconChannel2
        },
        {
            "id": 3,
            "title": "Infantil C3",
            "iconImg": iconChannel3
        },
        {
            "id": 4,
            "title": "Cinema C4",
            "iconImg": iconChannel4
        },
        {
            "id": 5,
            "title": "Comedia C5",
            "iconImg": iconChannel5
        }
    ]

    function wrapperRT(wrapper) {
        setTvWrapper(wrapper);
        const now = new Date();
        const target = new Date(now);
        target.setHours(0, 0, 0, 0);
        const diff = Math.abs(now - target) / 36e5;
        var rtarraykey = parseInt((wrapper?.length / 24) * diff);
        setArrayKey(rtarraykey);
        return wrapper;
    }

    const handleChannel = (e) => {
        e.preventDefault();

        setOpen(false);

        if (parseInt(changeChannel) !== parseInt(e.target.id) && parseInt(e.target.id) === parseInt(1)) {
            setSelectedChannel(iconChannel1);
            fetchWrapper();
        } else if (parseInt(changeChannel) !== parseInt(e.target.id) && parseInt(e.target.id) === parseInt(2)) {
            setSelectedChannel(iconChannel2);
            fetchWrapper1();
        } else if (parseInt(changeChannel) !== parseInt(e.target.id) && parseInt(e.target.id) === parseInt(3)) {
            setSelectedChannel(iconChannel3);
            fetchWrapper2();
        } else if (parseInt(changeChannel) !== parseInt(e.target.id) && parseInt(e.target.id) === parseInt(4)) {
            setSelectedChannel(iconChannel4);
            fetchWrapper3();
        } else if (parseInt(changeChannel) !== parseInt(e.target.id) && parseInt(e.target.id) === parseInt(5)) {
            setSelectedChannel(iconChannel5);
            fetchWrapper4();
        }

        setChangeChannel(e.target.id);
        /* setSelectedChannel('iconChannel' + e.target.id); */

    };

    const handleSonido = (e) => {
        e.preventDefault();
        if (muted) {
            setMuted(false);
        } else {
            setMuted(true);
        }
    };

    const handlePlay = (e) => {
        e.preventDefault();
        if (play) {
            setPlay(false);
            setPausePrev(true);
        } else if (!play && !showAd) {
            setPlay(true);
            setPausePrev(false);
        }
    };

    const handleImage = (e) => {
        setArrayKey(parseInt(e.target.id));
        setPlay(true);
    };

    async function fetchWrapper() {

        setIsLoading(true);

        if (todayNumber === parseInt(6) || todayNumber === parseInt(0)) {
            await axios.get(requests.fetchFamilySD)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        } else {
            await axios.get(requests.fetchFamilyLV)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        }
    }

    async function fetchWrapper1() {

        setIsLoading(true);

        if (todayNumber === parseInt(6) || todayNumber === parseInt(0)) {
            await axios.get(requests.fetchNews)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        } else {
            await axios.get(requests.fetchNews)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        }
    }

    async function fetchWrapper2() {

        setIsLoading(true);

        if (todayNumber === parseInt(6) || todayNumber === parseInt(0)) {
            await axios.get(requests.fetchCartoon)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        } else {
            await axios.get(requests.fetchCartoon)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        }
    }

    async function fetchWrapper3() {

        setIsLoading(true);

        if (todayNumber === parseInt(6) || todayNumber === parseInt(0)) {
            await axios.get(requests.fetchPeliculas)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        } else {
            await axios.get(requests.fetchPeliculas)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        }
    }

    async function fetchWrapper4() {

        setIsLoading(true);

        if (todayNumber === parseInt(6) || todayNumber === parseInt(0)) {
            await axios.get(requests.fetchComedia)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        } else {
            await axios.get(requests.fetchComedia)
                .then(response => {
                wrapperRT(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
        }
    }

    function toHoursAndMinutes(totalSeconds) {
        const totalMinutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const todo = `${hours}:${minutes}:${seconds}`;
        return todo
    }

    // Contador tiempo de Ads.
    useEffect(() => {
        const intervalId = setInterval(() => {
            setArrayKeyAds(0);
            setPlay(false);
            setShowDatavideo(false);
            setArrayAds(shuffle(adslist).slice(0, 1));
            setShowAd(true);
        }, 900000);
        return () => clearInterval(intervalId);
    }, [!showAd]);

    const toggleMenuNav = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleVolumeChange = (e) => {
        setVolume(parseFloat(e.target.value));
    };

    //Intro selected
    useEffect(() => {
        setArraySelectedIntro(shuffle(dmqintro).slice(0, 1));
        setUpdateSelectedIntro(false);
    }, [updateSelectedIntro])

    const volumeControl = (
        <div className="volume-control">
            <input
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
                className="volume-control__slider"
            />
        </div>
    );

    //Pause with spacebar
    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.code === 'Space') {
            event.preventDefault();
            if (playerRef.current && playerRef.current.getInternalPlayer()) {
            if (play) {
                playerRef.current.getInternalPlayer().pauseVideo();
                setPlay(false);
                setPausePrev(true);
            } else if (!play && !showAd) {
                playerRef.current.getInternalPlayer().playVideo();
                setPlay(true);
                setPausePrev(false);
            }
          }
        }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [play, playerRef]);

    return (
        <div className="prebanner">
            {isLoading
            ? (
                    <div className="banner__preload">

                        {channelsOpt.filter((channel) =>
                            parseInt(channel?.id) === parseInt(changeChannel)
                        ).map((channel) => (

                            <div className="banner__preload__left">
                                <img
                                    className="banner__preload__channel"
                                    src={channel?.icon}
                                    alt={channel?.name}
                                />
                                {timeLeft === 0 ? <h3 className="banner__preload__counterdown">¡Espera!</h3> : (<h3 className="banner__preload__counterdown">{timeLeft}</h3>)}
                                <div className="banner__preload__divapto">
                                    <p className="banner__preload__apto">
                                        {channel?.msg}
                                    </p>
                                </div>
                                <img
                                    className='banner__preload__logo'
                                    src={logo}
                                    alt="demoqrata" title="Sólo la verdad."
                                />
                            </div>
                        ))
                        }

                        <div className="banner__preload__right">
                            <img
                                className="banner__preload__img"
                                src={preloadGifImg?.urlgif || preload}
                                alt="Cargando demoqrata"
                            />
                        </div>

                    </div>
                )
                : (
                    <div id="divcode1" >
                        {tvWrapper.filter((news, index) =>
                            parseInt(index) === parseInt(arrayKey)
                        ).map((news, index) => (

                            <div id="divcode2">
                                <header id="head" key={index} className="banner">
                                    <FullScreen handle={handle}>
                                        <ReactPlayer
                                            ref={playerRef}
                                            id="video"
                                            key={index}
                                            className='react-player noHover'
                                            url={`https://www.youtube.com/embed/${news?.videoId}?rel=0`}
                                            volume={volume}
                                            playbackRate={1}
                                            width='100%'
                                            height='100%'
                                            playing={play && !playingIntermediateVideo}
                                            muted={muted}
                                            controls={false}

                                            onReady={handlePlayerReady}

                                            onError={(err) => {
                                                console.log("error:", err);

                                                if (parseInt(arrayKey) < tvWrapper.length - 1) {
                                                    setArrayKey(parseInt(arrayKey) + 1)
                                                } else {
                                                    setArrayKey(0)
                                                }
                                            }}

                                            onDuration={(state) => {
                                                if (state) {
                                                    setDuration(state);
                                                }
                                            }}

                                            onStart={() => {

                                                if (showAd) {
                                                    setPlay(false);
                                                } else if (!showAd) {
                                                    setPlay(true);
                                                }

                                                let element = document.getElementById(arrayKey);
                                                let container = document.getElementById("containerArray");

                                                let rect = element.getBoundingClientRect();
                                                let containerRect = container.getBoundingClientRect();
                                                let elementLeft = rect.left - containerRect.left;
                                                let elementWidth = rect.width;

                                                let containerScrollLeft = container.scrollLeft;
                                                let containerWidth = container.clientWidth;

                                                let centerOffset = (containerWidth - elementWidth) / 2;
                                                let targetScrollLeft = containerScrollLeft + elementLeft - centerOffset;

                                                let isAutoScrolling = false;
                                                let scrollInterval;

                                                container.addEventListener("scroll", function () {
                                                    if (!isAutoScrolling) {
                                                        containerScrollLeft = container.scrollLeft;
                                                    }
                                                });

                                                function scrollContainer() {
                                                    isAutoScrolling = true;
                                                    let scrollStep = Math.abs(targetScrollLeft - containerScrollLeft) / 50;
                                                    let scrollDirection = targetScrollLeft > containerScrollLeft ? 1 : -1;
                                                    clearInterval(scrollInterval);

                                                    scrollInterval = setInterval(function () {
                                                        if (Math.abs(targetScrollLeft - containerScrollLeft) > scrollStep) {
                                                            containerScrollLeft += scrollStep * scrollDirection;
                                                            container.scrollLeft = containerScrollLeft;
                                                        } else {
                                                            clearInterval(scrollInterval);
                                                            containerScrollLeft = targetScrollLeft;
                                                            container.scrollLeft = containerScrollLeft;
                                                            isAutoScrolling = false;
                                                        }
                                                    }, 10);
                                                }

                                                scrollContainer();

                                                let leftArrow = document.getElementById("leftArrowId");
                                                let rightArrow = document.getElementById("rightArrowId");

                                                leftArrow.addEventListener("click", function () {
                                                    manualScrollContainer(-350);
                                                });

                                                rightArrow.addEventListener("click", function () {
                                                    manualScrollContainer(350);
                                                });

                                                function manualScrollContainer(scrollAmount) {
                                                    clearInterval(scrollInterval);
                                                    isAutoScrolling = false;
                                                    let targetScrollLeft = containerScrollLeft + scrollAmount;
                                                    let scrollDirection = targetScrollLeft > containerScrollLeft ? 1 : -1;
                                                    let scrollStep = Math.abs(targetScrollLeft - containerScrollLeft) / 50;
                                                    scrollInterval = setInterval(function () {
                                                        if (Math.abs(targetScrollLeft - containerScrollLeft) > scrollStep) {
                                                            containerScrollLeft += scrollStep * scrollDirection;
                                                            container.scrollLeft = containerScrollLeft;
                                                        } else {
                                                            clearInterval(scrollInterval);
                                                            containerScrollLeft = targetScrollLeft;
                                                            container.scrollLeft = containerScrollLeft;
                                                        }
                                                    }, 10);
                                                }
                                            }}

                                            onProgress={(state) => {

                                                if (state) {
                                                    setProgress(state.playedSeconds);

                                                    if (parseInt(state.playedSeconds) === parseInt(duration) - 5) {
                                                        setShowDatavideo(false);
                                                    }
                                                }
                                            }}

                                            onEnded={handlePlayerEnded}

                                            config={{
                                                youtube: {
                                                    playerVars: { origin: window.location.origin, showinfo: 0, controls: 0 },
                                                    preload: true,
                                                    quality: quality,
                                                    file: { attributes: { crossOrigin: "true" } }
                                                },
                                            }}
                                        />

                                        {playingIntermediateVideo && (
                                            <ReactPlayer
                                                key={arraySelectedIntro[0]?.id}
                                                className='react-player noHover'
                                                url={`https://www.youtube.com/embed/${arraySelectedIntro[0]?.introId}?rel=0`}
                                                width='100%'
                                                height='100%'
                                                muted={muted}
                                                volume={volume}
                                                controls={false}
                                                playing={play}
                                                onEnded={handleIntermediateVideoEnded}
                                            />
                                        )}

                                        {showAd && (
                                            <div className="ad-container">

                                                {arrayAds.filter((adsplay, index) =>
                                                    parseInt(index) === parseInt(arrayKeyAds)
                                                ).map((adsplay, index) => (
                                                    <div key={index}>
                                                        <header className="bannerAd">
                                                            <ReactPlayer
                                                                key={index}
                                                                id='video'
                                                                className='react-player-ad noHover'
                                                                url={`https://www.youtube.com/embed/${adsplay?.videoId}?rel=0`}
                                                                volume={volume}
                                                                playbackRate={1}
                                                                width='100%'
                                                                height='100%'
                                                                playing={true}
                                                                muted={muted}
                                                                controls={false}

                                                                onError={() => {
                                                                    setShowAd(false);

                                                                    if (parseInt(arrayKeyAds) === parseInt(arrayAds.length) - 1) {
                                                                        setPlay(true);
                                                                    }
                                                                }}

                                                                onDuration={(state) => {
                                                                    if (state) {
                                                                        setDurationAd(state);
                                                                    }
                                                                }}

                                                                onProgress={(stateAd) => {

                                                                    if (stateAd) {
                                                                        setProgressAd(stateAd.playedSeconds);
                                                                    }
                                                                }}

                                                                onEnded={() => {
                                                                    if (parseInt(arrayKeyAds) < arrayAds.length) {
                                                                        setArrayKeyAds(parseInt(arrayKeyAds) + 1);
                                                                    } else {
                                                                        setShowAd(false);
                                                                    };

                                                                    if (!pausePrev && (parseInt(arrayKeyAds) === parseInt(arrayAds.length) - 1)) {
                                                                        setPlay(true);
                                                                        setShowAd(false);
                                                                        setShowDatavideo(true);
                                                                    } else if (parseInt(arrayKeyAds) === parseInt(arrayAds.length) - 1) {
                                                                        setShowAd(false);
                                                                        setShowDatavideo(true);
                                                                    }
                                                                }}

                                                                config={{
                                                                    youtube: {
                                                                        playerVars: { origin: window.location.origin, showinfo: 0, controls: 0 },
                                                                        preload: true,
                                                                        quality: quality,
                                                                        file: { attributes: { crossOrigin: "true" } }
                                                                    },
                                                                }}
                                                            />

                                                            <div className="banner__ad">
                                                                <img
                                                                    className="banner__ad__img"
                                                                    src={ad}
                                                                    alt="Ad"
                                                                    style={{
                                                                        cursor: "pointer", filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(103%) contrast(101%)", alignItems: "center", display: "flex"
                                                                    }}
                                                                    width="25px"
                                                                />
                                                                <p className="banner__ad__index"> {arrayKeyAds + 1}/{arrayAds.length}  </p>
                                                                <p className="banner__ad__duration"> {parseInt(durationAd - progressAd)} </p>
                                                            </div>

                                                        </header>

                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    </FullScreen>

                                    <div className="banner__frame">

                                        <div className={`banner__nav ${show && "banner__nav__black"}`}>
                                            <div id='video' className="banner__nav__contents">
                                                <Link to="/" style={{ textDecoration: 'none' }}>
                                                    <div className='banner__nav__logo'>
                                                        <img
                                                            src={logo}
                                                            alt="demoqrata" title="Sólo la verdad."
                                                        />
                                                    </div>
                                                </Link>

                                                <div className="banner__nav__contents__right">
                                                    {news?.category && news?.category?.includes("Película")
                                                        ? (
                                                            <div
                                                                className="banner__nav__contents__right__ticker"
                                                            >
                                                                <p>
                                                                    ESTÁS VIENDO: {news?.genero} : {news?.title}
                                                                    <span>●</span>
                                                                    A CONTINUACIÓN: {tvWrapper[parseInt(arrayKey + 1)]?.genero || ""} : {tvWrapper[parseInt(arrayKey + 1)]?.title || ""}
                                                                    <span>●</span>
                                                                    MÁS ADELANTE: {tvWrapper[parseInt(arrayKey + 2)]?.genero || ""} : {tvWrapper[parseInt(arrayKey + 2)]?.title || ""}
                                                                </p>
                                                            </div>
                                                        )
                                                        : (
                                                            <div
                                                                className="banner__nav__contents__right__ticker"
                                                            >
                                                                <p>
                                                                    ESTÁS VIENDO: {news?.authorname} : {news?.title}
                                                                    <span>●</span>
                                                                    A CONTINUACIÓN: {tvWrapper[parseInt(arrayKey + 1)]?.authorname || ""} : {tvWrapper[parseInt(arrayKey + 1)]?.title || ""}
                                                                    <span>●</span>
                                                                    MÁS ADELANTE: {tvWrapper[parseInt(arrayKey + 2)]?.authorname || ""} : {tvWrapper[parseInt(arrayKey + 2)]?.title || ""}
                                                                </p>
                                                            </div>
                                                        )
                                                    }

                                                    <div className="banner__nav__contents__right__accesos">

                                                        <div
                                                            className="banner__nav__contents__iconos">

                                                            <img
                                                                src={play ? pauseicon : playicon}
                                                                style={playIconStyle}
                                                                onClick={handlePlay}
                                                                alt={play ? "Pausar" : "Reproducir"}
                                                                title={play ? "Pausar" : "Reproducir"}
                                                            />

                                                            <div className="banner__nav__contents__iconos__sonidomobile">
                                                                <img
                                                                    src={muted ? iconsilencio : iconsonido}
                                                                    style={muteIconStyle}
                                                                    onClick={handleSonido}
                                                                    alt={muted ? "En silencio" : "Con sonido"}
                                                                    title={muted ? "En silencio" : "Con sonido"}
                                                                />
                                                            </div>

                                                            <div className="banner__nav__contents__iconos__sonidodesktop">
                                                                {!muted && (
                                                                    <div className="volume-icon-container" >
                                                                        <img
                                                                            className="banner__volumendesktop"
                                                                            src={iconsonido}
                                                                            onClick={handleSonido}
                                                                            alt="Volumen"
                                                                            title="Volumen"
                                                                        />
                                                                        {volumeControl}
                                                                    </div>
                                                                )}
                                                                {muted && (
                                                                    <div className="volume-icon-container" >
                                                                        <img
                                                                            src={iconsilencio}
                                                                            onClick={handleSonido}
                                                                            style={muteIconStyle}
                                                                            alt="En silencio"
                                                                            title="En silencio"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <img
                                                                className="banner__fullscreen"
                                                                src={fullscreen}
                                                                onClick={handle.enter}
                                                                alt="Pantalla completa"
                                                                title="Pantalla completa"
                                                            />

                                                        </div>

                                                        <div className="banner__nav__contents__channels" >
                                                            <div
                                                                className="banner__nav__contents__channels__img"
                                                            >
                                                                <img
                                                                    className="banner__channels"
                                                                    src={selectedChannel}
                                                                    ref={imgRef}
                                                                    onClick={() => setOpen(!open)}
                                                                    alt="Cambia de canal"
                                                                    title="Cambia de canal"
                                                                    width="80px"
                                                                />
                                                                <ArrowDropDownSharp
                                                                    style={{ opacity: "0.8" }}
                                                                />
                                                            </div>

                                                            {open && (
                                                                <div
                                                                    ref={menuRef}
                                                                    className="banner__nav__contents__channels__selection"
                                                                >
                                                                    {channelsMenu.map((menu) => (
                                                                        <div className="banner__nav__contents__channels__selectionicon">
                                                                            <img
                                                                                key={menu.id}
                                                                                id={menu.id}
                                                                                onClick={handleChannel}
                                                                                src={menu.iconImg}
                                                                                alt={menu.title}
                                                                                title={menu.title}
                                                                                width="80px"
                                                                            />
                                                                        </div>
                                                                    ))}

                                                                </div>
                                                            )
                                                            }

                                                        </div>

                                                        <div className="banner__container__menu">
                                                            <img
                                                                className="banner__menu"
                                                                src={menuvert}
                                                                onClick={toggleMenuNav}
                                                                alt="Menu"
                                                                title="Menu"
                                                            />

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            {isMenuOpen && (
                                                <div>
                                                {usuarioGlobal
                                                ? (
                                                <div className="banner__menu-box">
                                                    <div className="banner__close-icon" onClick={toggleMenuNav}>
                                                        <img
                                                            className="banner__menu__close"
                                                            src={closemenu}
                                                            alt="Cerrar"
                                                            title="Cerrar"
                                                        />
                                                    </div>
                                                    <div className="banner__menu__option">
                                                        <h2 className="banner__menu__option__username">Hola {datauser && datauser.firstname}</h2>
                                                        <PayPalDonation />
                                                        <div className="banner__menu__option__link">
                                                            <Link to="/profile" style={{ textDecoration: 'none' }}>
                                                            <a className="banner__menu__icon__linkdom" >
                                                                <img
                                                                    className="banner__menu__icon__img"
                                                                    src={profileicon}
                                                                    alt="Profile"
                                                                />Perfil</a>
                                                            </Link>

                                                            <Link to="/categories" style={{ textDecoration: 'none' }}>
                                                            <a className="banner__menu__icon__linkdom">
                                                                <img
                                                                    className="banner__menu__icon__img"
                                                                    src={categoryicon}
                                                                    alt="Category"
                                                                />Categorías</a>
                                                            </Link>
                                                            
                                                            <a href="https://2024.demoqrata.tv" target="_blank" rel="noopener noreferrer">
                                                                <img
                                                                    className="banner__menu__icon__img"
                                                                    src={ad}
                                                                    alt="Ad"
                                                                />
                                                                Anuncia aquí</a>

                                                            <a href="https://help.demoqrata.tv/" target="_blank" rel="noopener noreferrer">
                                                                <img
                                                                    className="banner__menu__icon__img"
                                                                    src={help}
                                                                    alt="Helpdesk"
                                                                />
                                                                Centro de Ayuda</a>
                                                            
                                                            <a 
                                                            className="banner__menu__icon__linkdom"
                                                            onClick={() => {
                                                            auth.signOut();
                                                            history.push("/");
                                                            }}>
                                                                <img
                                                                    className="banner__menu__icon__img"
                                                                    src={signout}
                                                                    alt="Salir de demoqrata"
                                                                />
                                                            Salir de demoqrata</a>
                                                        </div>
                                                        <p>
                                                            demoqrata 2022-2024.
                                                            Representada legalmente en Latinoamérica por Insighter del Perú S.A.C. RUC N°20536307401
                                                        </p>
                                                    </div>
                                                </div>
                                                )
                                                : (
                                                <div className="banner__menu-box">
                                                    <div className="banner__close-icon" onClick={toggleMenuNav}>
                                                        <img
                                                            className="banner__menu__close"
                                                            src={closemenu}
                                                            alt="Cerrar"
                                                            title="Cerrar"
                                                        />
                                                    </div>
                                                    <div className="banner__menu__option">
                                                        <PayPalDonation />
                                                        <div className="banner__menu__option__link">
                                                            <a href="https://2024.demoqrata.tv" target="_blank" rel="noopener noreferrer">
                                                                <img
                                                                    className="banner__menu__icon__img"
                                                                    src={ad}
                                                                    alt="Ad"
                                                                />
                                                                Anuncia aquí</a>

                                                            <a href="https://help.demoqrata.tv/" target="_blank" rel="noopener noreferrer">
                                                                <img
                                                                    className="banner__menu__icon__img"
                                                                    src={help}
                                                                    alt="Helpdesk"
                                                                />
                                                                Centro de Ayuda</a>
                                                            
                                                            <a 
                                                            className="banner__menu__icon__linkdom"
                                                            onClick={() => {
                                                            history.push("/signin");
                                                            }}>
                                                                <img
                                                                    className="banner__menu__icon__img"
                                                                    src={signin}
                                                                    alt="Entrar a demoqrata"
                                                                />
                                                            Entra a demoqrata</a>
                                                        </div>
                                                        <p>
                                                            demoqrata 2022-2024.
                                                            Representada legalmente en Latinoamérica por Insighter del Perú S.A.C. RUC N°20536307401
                                                        </p>
                                                    </div>
                                                </div>
                                                )
                                                }
                                            </div>
                                            )}
                                        </div>
                                    </div>

                                    {showDatavideo &&
                                        <div className="banner__datavideo" key={index} >

                                            {news?.category && news?.category?.includes("Película")
                                                ? (
                                                <div className="banner__datavideo__author">
                                                    <h2>{news?.title}</h2>
                                                </div>
                                                )
                                                : (
                                                <div className="banner__datavideo__author">
                                                    {news?.chapternumber
                                                        ? (<h2>{news?.title} cap. #{news?.chapternumber}</h2>)
                                                        : (<h2>{news?.authorname}</h2>)
                                                    }
                                                </div>
                                                )
                                            }

                                            <div className="banner__datavideo__info">
                                                <div className="banner__datavideo__info__title">
                                                    <h1>{news?.title}</h1>
                                                </div>
                                                <div className="banner__datavideo__info__ticker">
                                                    {news?.category &&
                                                        (
                                                            (news?.category?.includes("Información")) ||
                                                            (news?.category?.includes("Opinión")) ||
                                                            (news?.category?.includes("Investigación")) ||
                                                            (news?.category?.includes("Documental"))
                                                        )
                                                        ? (
                                                            <div className="banner__timestamp">
                                                                <AccessTimeSharp
                                                                    style={{ padding: "0 5px" }}
                                                                />
                                                                {" "}
                                                                {(new Date(news?.published)).getDate()}
                                                                {"/"}
                                                                {((new Date(news?.published)).getMonth()) + 1}
                                                                {"/"}
                                                                {(new Date(news?.published)).getFullYear()}
                                                            </div>
                                                        )
                                                        : (
                                                            <div className="banner__timestamp">
                                                                <AccessTimeSharp
                                                                    style={{ padding: "0 5px" }}
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                    <div className="banner__ticker">
                                                        <p>
                                                            {news?.description}
                                                        </p>
                                                    </div>

                                                    <div className="banner__progress">
                                                        {news?.type || news?.genero
                                                            ? (<p className="banner__progress__category">{news?.type || news?.genero}</p>)
                                                            : (<p className="banner__progress__category">{news?.category}</p>)
                                                        }
                                                        <div className="banner__progress__counter1">
                                                            {toHoursAndMinutes(parseInt(progress))}
                                                        </div>
                                                        <div className="banner__progress__counter2">
                                                            {"[" + toHoursAndMinutes(parseInt(duration)) + "]"}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="banner__datavideo__category">
                                                {news?.type || news?.genero
                                                    ? (<p>{news?.type || news?.genero}</p>)
                                                    : (<p>{news?.category}</p>)
                                                }
                                            </div>

                                        </div>
                                    }
                                </header>

                                <div id="head" className="banner__row" >
                                    {/* <div className="banner__row__container">
                                        <h2>La Programación de Hoy</h2>
                                    </div> */}

                                    <button id="leftArrowId" className="banner__row__poster__left">
                                        < NavigateBeforeSharp style={{ fontSize: 50 }} />
                                    </button>

                                    <button id="rightArrowId" className="banner__row__poster__right">
                                        < NavigateNextSharp style={{ fontSize: 50 }} />
                                    </button>

                                    <div id="containerArray" className="banner__row__posters">

                                        {tvWrapper.map((video, index) => (
                                            <div id={index} key={index} className={`banner__row__poster__props`} >
                                                <ScrollVideo to="video" offset={0} smooth={true} delay={300} style={{ textDecoration: 'none' }}>
                                                    {parseInt(index) === parseInt(arrayKey)
                                                        ? (
                                                            <p
                                                                className="banner__row__poster__props__catselected"
                                                                id={index}
                                                                onClick={handleImage}
                                                            >   Estás viendo:
                                                            </p>
                                                        )
                                                        : (

                                                            <div>
                                                                {video?.type || video?.genero
                                                                    ? (
                                                                        <p
                                                                            className="banner__row__poster__props__category"
                                                                            id={index}
                                                                            onClick={handleImage}
                                                                        >{video?.type || video?.genero}
                                                                            <img
                                                                                src={fromicon}
                                                                                id={index}
                                                                                onClick={handleImage}
                                                                                alt="ver desde aquí"
                                                                                title="ver desde aquí"
                                                                                width="20px"
                                                                            />
                                                                        </p>
                                                                    )
                                                                    : (
                                                                        <p
                                                                            className="banner__row__poster__props__category"
                                                                            id={index}
                                                                            onClick={handleImage}
                                                                        >{video?.category}
                                                                            <img
                                                                                src={fromicon}
                                                                                id={index}
                                                                                onClick={handleImage}
                                                                                alt="ver desde aquí"
                                                                                title="ver desde aquí"
                                                                                width="20px"
                                                                            />
                                                                        </p>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    <img
                                                        id={index}
                                                        key={index}
                                                        onClick={handleImage}
                                                        className={`banner__row__poster`}
                                                        src={`${base_url}${video?.videoId}${end_url}`} alt={video?.title}
                                                        title="ver desde aquí"
                                                    />
                                                </ScrollVideo>
                                                <p className={`banner__row__poster__author`}>{video?.authorname}</p>

                                                <div className={`banner__row__poster__details`}>
                                                    {video?.chapternumber
                                                        ? (<h2 className={`banner__row__poster__title`}>{video?.title} cap. #{video?.chapternumber}</h2>)
                                                        : (<h2 className={`banner__row__poster__title`}>{video?.title}</h2>)
                                                    }
                                                    
                                                </div>
                                            </div>

                                        ))
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
                
            }
        </div>
    );
}

export default Banner;